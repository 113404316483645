import React, { Component } from 'react';
import style from './style.scss';
import ContactPersonList from './ContactPersonList';
import { connect } from 'react-redux';
import { fetchContactPersons } from '../../actions';
import ContactPersonEdit from '../ContactPersonEdit';
import ContactPersonDelete from './ContactPersonDelete';

class ManageUsers extends Component {
  state = {
    showEditContatctPerson: false,
    selectedContactPerson: null,
    showDeleteContactPerson: false,
  };
  componentDidMount() {
    const { onGetContactPersons } = this.props;
    onGetContactPersons();
    this.scrollref = React.createRef();
  }
  handleOpenContactPerson = contactPerson => {
    this.setState({
      showEditContatctPerson: true,
      selectedContactPerson: {
        ...contactPerson,
        editMode: contactPerson.ContactPersonIdentifier ? true : false,
      },
    });
  };
  handleContactPersonModalClose = () => {
    this.setState({
      showEditContatctPerson: false,
      selectedContactPerson: null,
    });
  };

  handleOpenDeleteContactPersonModal = contactPerson => {
    this.setState({
      showDeleteContactPerson: true,
      selectedContactPerson: contactPerson,
    });
  };

  handleDeleteContactPersonModalClose = () => {
    this.setState({
      showDeleteContactPerson: false,
      selectedContactPerson: null,
    });
  };

  render() {
    const { contactPersons } = this.props;
    const {
      showEditContatctPerson,
      selectedContactPerson,
      showDeleteContactPerson,
    } = this.state;
    return (
      <>
        {showEditContatctPerson && (
          <ContactPersonEdit
            {...selectedContactPerson}
            showModal={showEditContatctPerson}
            // onChange={this.handleChangeContactPerson}
            onHandleModal={this.handleContactPersonModalClose}
            // onHandleConfirm={this.handleConfirm}
          />
        )}
        <div className="col-12 px-0 mx-0 w-100">
          <ContactPersonList
            data={contactPersons}
            onAddEditcontactPerson={this.handleOpenContactPerson}
            onDeleteContactPerson={this.handleOpenDeleteContactPersonModal}
          />
        </div>
        {showDeleteContactPerson && (
          <ContactPersonDelete
            {...selectedContactPerson}
            showDeleteModal={showDeleteContactPerson}
            onCloseModal={this.handleDeleteContactPersonModalClose}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const contactPersons = state.user.contactPersons || [];
  return {
    contactPersons,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetContactPersons: () => {
      dispatch(fetchContactPersons());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
