import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import StarGroup from '../StarGroup';
import {
  Translate,
  Modal,
  Row,
  Col,
  DropdownList,
  TextField,
} from '../../../Share/components';
import CreateDeviation from '../CreateDeviation';
import { isIE } from '../../../Share/utils';
import { PAGE_TYPES } from '../../../Share/constants';
import './style.scss';
import { getAttachment, setOrderRating, createDeviation } from '../../actions';
import SuccessMessage from '../NewAssignment/SuccessMessage';

const propTypes = {
  assignmentDetail: PropTypes.shape({}),
  localWorkAssignment: PropTypes.shape({}),
  onOrderRated: PropTypes.func,
  getRateReasons: PropTypes.func,
  from: PropTypes.string,
  ratingReasons: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  assignmentDetail: {},
  onOrderRated: () => {},
  getRateReasons: () => {},
  localWorkAssignment: {},
  from: '',
};

class TimeSheet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: 0,
      ratingReason: '',
      comment: '',
      isDeviation: false,
      showDeviation: false,
      showSuccessMessage: false,
      editRating: true,
      deviationSuccess: false,
    };
  }

  getValueFromList = (filterText, list) => {
    if (filterText === null || filterText === '') return null;
    const filteredItems = list.filter(x => x.value === filterText);
    return filteredItems ? filteredItems[0] : null;
  };

  deviationFeedBack = DeviationNumber => {
    this.setState({
      showSuccessMessage: true,
      deviationNumber: DeviationNumber,
    });
  };

  rateFeedBack = feedback => {
    const { from } = this.props;
    const { isDeviation } = this.state;
    if (feedback.IsSuccess && isDeviation) {
      this.setState({
        showDeviation: true,
      });
    } else if (feedback.IsSuccess && !isDeviation) {
      if (from === PAGE_TYPES.DASHBOARD) {
        window.location.href = '/';
      } else if (from === PAGE_TYPES.ASSIGNMENT) {
        window.location.href = '/my-assignment';
      }
    }
    return null;
  };

  navigateOnDeviationSuccess = () => {
    const { from } = this.props;
    if (from === PAGE_TYPES.DASHBOARD) {
      window.location.href = '/';
    } else if (from === PAGE_TYPES.ASSIGNMENT) {
      window.location.href = '/my-assignment';
    }
    return null;
  };

  handleSubmit = () => {
    const { rating, ratingReason, comment } = this.state;

    const { assignmentDetail, onOrderRated } = this.props;

    if (rating !== 0) {
      this.setState({
        editRating: false,
      });
      onOrderRated(
        assignmentDetail?.OrderIdentifier,
        parseInt(rating, 10),
        comment,
        ratingReason,
        this.rateFeedBack,
      );
    }
  };

  setShowDeviationModal = status => {
    this.setState({
      showDeviation: status,
    });
  };

  handleRate = event => {
    const {
      target: { type, name, value },
    } = event;
    if (type === 'checkbox') {
      this.setState(prevState => ({
        isDeviation: !prevState.isDeviation,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  RouteLeavingModalHandler = value => {
    this.setState({
      visibleRouteLeavingModal: value,
    });
  };

  createDeviation = () => {
    const { rating, ratingReason, comment } = this.state;
    const { assignmentDetail, onOrderRated } = this.props;

    this.setState({
      showDeviation: true,
    });

    // this.setState({ isDeviation: true });
    // if (rating !== 0) {
    //   onOrderRated(
    //     assignmentDetail?.OrderIdentifier,
    //     parseInt(rating, 10),
    //     comment,
    //     ratingReason,
    //     this.rateFeedBack,
    //   );
    // }
  };

  handleRateFeedback = () => {
    const { rating, ratingReason, comment } = this.state;
    const { assignmentDetail, onOrderRated } = this.props;
    if (rating !== 0) {
      onOrderRated(
        assignmentDetail?.OrderIdentifier,
        parseInt(rating, 10),
        comment,
        ratingReason,
      );
    }
    this.setState({
      deviationSuccess: true,
    });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { ratingReasons, showModal, onHandleModal, assignmentDetail } =
      this.props;
    const {
      rating,
      ratingReason,
      comment,
      showSuccessMessage,
      deviationNumber,
      showDeviation,
      editRating,
      deviationSuccess,
    } = this.state;
    return (
      <div>
        <Modal
          isVisible={showModal}
          className="tv-workassignment__modal feedBack__modal"
          onCloseClicked={() => {
            onHandleModal();
            if (deviationSuccess) {
              this.navigateOnDeviationSuccess();
            }
          }}
          onOverlayClick={() => {
            onHandleModal();
            if (deviationSuccess) {
              this.navigateOnDeviationSuccess();
            }
          }}
          headerClassName={`tv-workassignment__modal-header ${
            isIE ? 'ie-fix-width' : ''
          }`}
          headerText={
            showDeviation || showSuccessMessage
              ? Translate({
                  content: 'report.sendDeviation',
                })
              : Translate({
                  content: 'calendar.feedBackModalTitle',
                })
          }
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        >
          {showSuccessMessage && (
            <SuccessMessage
              mode="deviation"
              onClose={() => {
                this.setState({
                  showSuccessMessage: false,
                });
                onHandleModal();
                this.navigateOnDeviationSuccess();
              }}
              visible={showSuccessMessage}
              deviationNumber={deviationNumber}
            />
          )}

          {showDeviation && (
            <CreateDeviation
              assignment={assignmentDetail}
              isGeneral={false}
              onCloseClicked={() => {
                this.setShowDeviationModal(false);
              }}
              onDeviationSuccess={createdDeviationNumber => {
                this.setShowDeviationModal(false);
                this.deviationFeedBack(createdDeviationNumber);
              }}
              onHandleRateFeedback={this.handleRateFeedback}
            />
          )}

          {!showSuccessMessage && !showDeviation && (
            <ValidatorForm
              ref="form"
              className="tv-reportquality-form "
              onSubmit={this.handleSubmit}
            >
              <div>
                <Row>
                  <div className="feedBack">
                    <Row>
                      <Col md={12} sm={12}>
                        <div className="feedBack-header">
                          {Translate({
                            content: 'calendar.feedBackTitle',
                          })}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={12}>
                        <div className="feedBack-content">
                          Hur gick tolkuppdraget? 1-2 stjärnor betyder dåligt
                          och du får möjlighet att skapa en avvikelse. 5
                          stjärnor betyder väldigt bra.
                        </div>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col md={12} sm={12}>
                        <div className="star-group">
                          <StarGroup
                            className="star-group-container"
                            size={40}
                            value={rating}
                            activeColor="#FF9B4E"
                            handleChange={e =>
                              this.handleRate({
                                target: {
                                  name: 'rating',
                                  value: e,
                                },
                              })
                            }
                            editRating={editRating}
                          />
                        </div>
                      </Col>
                    </Row>
                    {rating <= 2 && rating > 0 && (
                      <div className="form-wrapper">
                        <Row>
                          <Col md={12} sm={12}>
                            <span className="rating-label">
                              <Translate content="reason.writeAComment" />
                            </span>
                            <div className="field-wrapper">
                              <TextField
                                multiline
                                className="ellipsis pl-3 py-1 tv-single-assign-form__control "
                                placeholder={Translate({
                                  content: 'timesheet.descripeYourRating',
                                })}
                                cols="10"
                                name="comment"
                                rows="3"
                                onChange={this.handleRate}
                                value={comment}
                                maxLength="1000"
                              />
                            </div>
                          </Col>
                        </Row>
                        {!assignmentDetail.HasDeviation && (
                          <Row>
                            <Col md={6}>
                              <div
                                className="feedback-create-deviation"
                                onClick={this.createDeviation}
                              >
                                {Translate({
                                  content: 'reportQuality.isADeviation',
                                })}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    )}
                    {rating > 2 && (
                      <Row>
                        <Col md={12} sm={12}>
                          <span className="rating-label">
                            <Translate content="reason.writeAComment" />
                          </span>
                          <div className="field-wrapper">
                            <TextField
                              multiline
                              className="ellipsis pl-3 py-1 tv-single-assign-form__control label-height"
                              placeholder={Translate({
                                content: 'timesheet.descripeYourRating',
                              })}
                              cols="10"
                              name="comment"
                              rows="3"
                              onChange={this.handleRate}
                              value={comment}
                              maxLength="1000"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <button
                          onClick={() => {
                            onHandleModal();
                          }}
                          className="timesheet-button_cancel"
                        >
                          <Translate content="timesheet.cancel" />
                        </button>
                      </Col>
                      <Col>
                        <button
                          className="timesheet-button_submit"
                          type="submit"
                        >
                          <Translate content="reason.sendFeedBack" />
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>
            </ValidatorForm>
          )}
        </Modal>
      </div>
    );
  }
}

TimeSheet.propTypes = propTypes;
TimeSheet.defaultProps = defaultProps;

const mapStateToProps = state => ({
  // assignmentDetail: state.workAssignment.assignmentDetail,
  localWorkAssignment: state.localWorkAssignment,
  token: state.authentication.token,
  ratingReasons: state.workAssignment.ratingReasons,
});

const mapDispatchToProps = dispatch => ({
  getAttachment: e => {
    dispatch(getAttachment(e));
  },

  onOrderRated: (orderIdentifier, rate, comment, reason, callback) => {
    dispatch(setOrderRating(orderIdentifier, rate, comment, reason, callback));
  },

  onCreateDeviation: (data, callback) => {
    dispatch(createDeviation(data, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheet);
