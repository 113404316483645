import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SideBar, RightStatus, MobileNavigation } from '../components';
import { LoadingOverlay, Logo } from '../../Share/components';
import {
  fetchArticles,
  fetchSkills,
  getNotificationList,
} from '../actions/common';
import { createUpdateWebSession } from '../../Share/utils/browserUtil';
import {
  onUpdateSearchByOrder,
  setCustomerKPIReportsDispalyRight,
} from '../actions';
import history from '../../Share/utils/history';

const propTypes = {
  children: PropTypes.element.isRequired,
  fetchArticles: PropTypes.func.isRequired,
  fetchSkills: PropTypes.func.isRequired,
  checkCustomerAllowedToAccessKPIReport: PropTypes.func.isRequired,
  onUpdateSearchByOrder: PropTypes.func.isRequired,
  displayKPIReports: PropTypes.bool,
  searchOrder: PropTypes.string,
  CanCreateAutoAssignOrder: PropTypes.bool,
  customerNumber: PropTypes.number,
};

const defaultProps = {
  displayKPIReports: false,
  searchOrder: '',
  CanCreateAutoAssignOrder: false,
};

const INITAL_STATES = {
  visibleMobileNavigation: false,
  showBookDirectModal: false,
};

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
    history.listen(location => {
      if (location.pathname !== '/search-result') {
        this.props.onUpdateSearchByOrder('');
      }
    });
  }
  componentDidMount() {
    // this.props.fetchArticles();
    this.props.fetchSkills();
    this.props.checkCustomerAllowedToAccessKPIReport();
    this.props.getNotificationList();
    const vh = document.body.scrollHeight;
    const rightWrapper = document.querySelector('.tv-right-pannel-wrapper');
    if (rightWrapper) {
      rightWrapper.style.setProperty('--vh', `${vh}px`);
    }

    createUpdateWebSession(this.props.customerNumber);
  }

  onClickNavigationButton = event => {
    this.setState({
      ...this.state,
      visibleMobileNavigation: !this.state.visibleMobileNavigation,
    });
  };

  handleBookDirectModal = () => {
    this.setState({
      ...this.state,
      showBookDirectModal: !this.state.showBookDirectModal,
    });
  };

  render() {
    const { displayKPIReports, CanCreateAutoAssignOrder } = this.props;
    const { visibleMobileNavigation, showBookDirectModal } = this.state;
    const mobileWrapperClass = visibleMobileNavigation ? 'mobile-wrapper' : '';

    return (
      <React.Fragment>
        <div className="tv-container">
          <div className="tv-display-flex">
            <div className={`tv-right-pannel-wrapper ${mobileWrapperClass}`}>
              <RightStatus
                onClickNavigationButton={this.onClickNavigationButton}
                displayKPIReports={displayKPIReports}
                handleBookDirectModal={this.handleBookDirectModal}
                showBookDirectModal={showBookDirectModal}
                CanCreateAutoAssignOrder={CanCreateAutoAssignOrder}
              />
              <div className="tv-right-content">{this.props.children}</div>
            </div>
            {visibleMobileNavigation}
            <MobileNavigation
              visible={visibleMobileNavigation}
              onClickNavItem={this.onClickNavigationButton}
              displayKPIReports={displayKPIReports}
              handleBookDirectModal={this.handleBookDirectModal}
              showBookDirectModal={showBookDirectModal}
              CanCreateAutoAssignOrder={CanCreateAutoAssignOrder}
            />
          </div>
        </div>
        <LoadingOverlay />
      </React.Fragment>
    );
  }
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const mapStateToProps = state => ({
  displayKPIReports: state.authentication.displayKPIReports,
  CanCreateAutoAssignOrder: state.user.profile.CanCreateAutoAssignOrder,
  skills: state.skill.skills || [],
  customerNumber: state.authentication.customerNumber,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchArticles: () => {
    dispatch(fetchArticles());
  },
  fetchSkills: () => {
    dispatch(fetchSkills());
  },
  checkCustomerAllowedToAccessKPIReport: () => {
    dispatch(setCustomerKPIReportsDispalyRight());
  },
  onUpdateSearchByOrder: e => {
    dispatch(onUpdateSearchByOrder(e));
  },
  getNotificationList: () => {
    dispatch(
      getNotificationList(
        null,
        false,
        moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm'),
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
