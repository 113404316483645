import React from 'react';
import ContactPersonItem from './ContactPersonItem';
import { Translate } from '../../../Share/components';
import './style.scss';

const ContactPersonList = ({
  data,
  onAddEditcontactPerson,
  onDeleteContactPerson,
}) => (
  <>
    <div className="w-100 d-flex justify-content-end">
      <button
        className="tv_dashboard__add-button"
        type="button"
        onClick={onAddEditcontactPerson}
      >
        <i className="fa fa-plus" />{' '}
        {Translate({
          content: 'manageUsers.addContactPerson',
        })}
      </button>
    </div>
    <div className="row tv-reportquality__list-item-parent tv-reportquality__list-item-header d-none d-md-flex mb-0 custom">
      <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl">
        <div className="tv-reportquality__item-text">Namn</div>
      </div>
      <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl ">
        <div className="tv-reportquality__item-text">Email</div>
      </div>
      <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl ">
        <div className="tv-reportquality__item-text">Kontaktnummer</div>
      </div>
      <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl ">
        <div className="tv-reportquality__item-text"></div>
      </div>
      <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl ">
        <div className="tv-reportquality__item-text"></div>
      </div>
    </div>
    <div className="tv-contactperson__list-wrapper">
      {data.map(item => (
        <ContactPersonItem
          item={item}
          onEdit={onAddEditcontactPerson}
          onDelete={onDeleteContactPerson}
        />
      ))}
    </div>
  </>
);
export default ContactPersonList;
