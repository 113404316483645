import React, { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm';
import { PreviewAssignment } from '.';
import { Modal, Translate, TransvoiceButton } from '../../../Share/components';
import {
  isAddressEdited,
  isContactPersonValid,
  isIE,
} from '../../../Share/utils';
import { currentDate } from '../../../Share/utils/dateUtil';
import { subscribe } from '../../actions/eventBus';
import SingleAssignmentForm from './SingleAssignmentForm';
import SuccessMessage from './SuccessMessage';

ValidatorForm.addValidationRule('urlformat', value => {
  if (value && value == '') return true;
  if (value && (value.startsWith('http://') || value.startsWith('https://'))) {
    return true;
  }
  return false;
});

export default function AdvancedAssigmentWizard({
  assignment,
  handleValidatorListener,
  handleChange,
  handleModal,
  languages,
  profile,
  onCloseAdvancedModal,
  onCloseBooking,
  onCreateNewDefaultForm,
  onSwitchToDetailOrder,
  visible,
}) {
  const [showStep, setShowStep] = useState(
    assignment?.typeOfAssignment?.name?.includes('Video')
      ? {
          showFirstStep: true,
          showSecondStep: false,
        }
      : {
          showFirstStep: false,
          showSecondStep: true,
        },
  );

  const [successFulOrder, setSuccessFulOrder] = useState({
    showMessage: false,
    messageDetail: [],
  });

  const [showPreview, setShowPreview] = useState(false);
  const scrollRef = useRef(null);
  const formRef = useRef(null);

  const handleFormError = e => {
    if (e.length > 0) {
      e[0].input.scrollIntoView
        ? e[0].input.scrollIntoView({ behavior: 'smooth' })
        : e[0].input._element.scrollIntoView({ behavior: 'smooth' });
    } else {
      e.input.scrollIntoView
        ? e.input.scrollIntoView({ behavior: 'smooth' })
        : e.input._element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const ConvergeValidate = () => {
    let isValid = true;
    const selectedDate =
      assignment.dateRange.ranges.startDate.format('DD/MM/YYYY');
    const todayDate = currentDate().format('DD/MM/YYYY');
    if (selectedDate === todayDate && !assignment.isEdit) isValid = false;
    return isValid;
  };

  const handleSubmit = () => {
    let isValid = true;
    let validContactPersonUponInterpretation = true;
    let validContactPersonOrderer = true;
    if (ConvergeValidate()) {
      if (
        !isContactPersonValid(
          profile.ContactPersonUponInterpretationRequiresEmail,
          assignment.contactPerson,
          assignment.contactPersons,
        )
      ) {
        validContactPersonUponInterpretation = false;
      }
      if (
        !isContactPersonValid(
          profile.ContactPersonOrdererRequiresEmail,
          assignment.orderContactPerson,
          assignment.contactPersons,
        )
      ) {
        validContactPersonOrderer = false;
      }
      isValid =
        validContactPersonUponInterpretation && validContactPersonOrderer;
      onSwitchToDetailOrder({
        ...assignment,
        isAddressEdited: isAddressEdited(assignment, profile),
      });
      if (isValid) {
        setShowPreview(true);
      }
    }
  };

  useEffect(() => {
    subscribe('SuccessFullOrderCreation', ({ detail, assignmentDetails }) => {
      if (assignmentDetails) {
        setShowPreview(false);
        setSuccessFulOrder({
          showMessage: true,
          messageDetail: assignmentDetails,
        });
      }
    });
  }, []);

  const ModalHeaderRender = () => (
    <div
      className={`tv-workassignment__modal-header row ml-0 ${
        isIE ? 'ie-fix-width' : ''
      }`}
    >
      <span className="col-5 pl-0">
        {Translate({
          content:
            // eslint-disable-next-line no-nested-ternary
            showStep.showFirstStep || showStep.showSecondStep
              ? assignment.isEdit
                ? 'dashboard.editAssignment'
                : 'singleAssignment.assigmentBooking'
              : !successFulOrder.showMessage
              ? 'singleAssignment.assignmentDetail'
              : 'singleAssignment.successModalHeader',
        })}
      </span>
      <span className="secondary-heading">
        {showStep.showFirstStep && (
          <Translate content="singleAssignment.wizardFirstStep" />
        )}
        {showStep.showSecondStep && (
          <Translate content="singleAssignment.wizardSecondStep" />
        )}
      </span>
    </div>
  );

  const onClickSubmit = e => {
    if (showStep.showSecondStep) {
      handleSubmit(e);
      setShowStep({
        showSecondStep: false,
        showFirstStep: false,
      });
    } else {
      setShowStep({
        showSecondStep: true,
        showFirstStep: false,
      });
    }
  };

  return (
    <>
      <Modal
        className="tv-workassignment__modal"
        onCloseClicked={() => {
          if (successFulOrder.showMessage) {
            onCloseBooking();
          } else {
            onCloseAdvancedModal();
          }
        }}
        isVisible={visible}
        headerRender={<ModalHeaderRender />}
        ieOptionClassName={isIE ? 'ie-fix-height' : ''}
      >
        {successFulOrder.showMessage && (
          <SuccessMessage
            onClose={() => {
              onCloseBooking();
            }}
            mode={assignment.isEdit ? 'edit' : 'assignment'}
            onContinueBooking={() => {
              onCreateNewDefaultForm();
              setSuccessFulOrder({
                showMessage: false,
                messageDetail: {},
              });
              setShowStep({
                showSecondStep: false,
                showFirstStep: true,
              });
            }}
            visible={successFulOrder.showMessage}
            assignmentDetails={successFulOrder.messageDetail}
          />
        )}
        {(showStep.showFirstStep || showStep.showSecondStep) && (
          <ValidatorForm
            ref={formRef}
            onSubmit={onClickSubmit}
            onError={handleFormError}
          >
            <div className="d-flex flex-column border-top-0 mb-5 mb-md-0">
              {/* <Scrollbars
                ref={scrollRef}
                className="tv-advanced-order__scroll-container"
              > */}
              <SingleAssignmentForm
                {...assignment}
                showOtherRequirementSection
                validatorListener={handleValidatorListener}
                showFirstStep={showStep.showFirstStep}
                showSecondStep={showStep.showSecondStep}
                onChange={handleChange}
                onHandleModal={handleModal}
                languages={languages}
                createdFrom="singleAssignment"
                ContactPersonOrdererRequiresEmail={
                  profile.ContactPersonOrdererRequiresEmail
                }
                ContactPersonUponInterpretationRequiresEmail={
                  profile.ContactPersonUponInterpretationRequiresEmail
                }
                isSingle
              />
              {/* </Scrollbars> */}
            </div>
            <div className="d-flex flex-column tv-workassignment__modal-footer">
              <div className="tv-workassignment__detail-buttons justify-content-between tv-display-flex">
                {showStep.showFirstStep && (
                  <>
                    <TransvoiceButton
                      type="secondary"
                      buttonType="submit"
                      className="w-50 ml-1"
                      text={Translate({
                        content: 'singleAssignment.gotoSecondStep',
                      })}
                    />
                    <TransvoiceButton
                      type="line-primary"
                      buttonType="button"
                      className="w-50 ml-1"
                      text={Translate({
                        content: 'general.close',
                      })}
                      onClick={onCloseAdvancedModal}
                    />
                  </>
                )}
                {showStep.showSecondStep && (
                  <>
                    <TransvoiceButton
                      type="secondary"
                      buttonType="submit"
                      className="tv-single-assign-form__button-preview"
                      text={Translate({
                        content: 'singleAssignment.previewAssignment',
                      })}
                    />
                    <TransvoiceButton
                      type="line-primary"
                      buttonType="button"
                      className="w-50 ml-1"
                      text={Translate({
                        content: 'singleAssignment.goBack',
                      })}
                      onClick={e => {
                        setShowStep({
                          showFirstStep: true,
                          showSecondStep: false,
                        });
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </ValidatorForm>
        )}
        {showPreview && (
          <PreviewAssignment
            onClose={() => {
              setShowPreview(false);
              setShowStep({
                showSecondStep: true,
                showFirstStep: false,
              });
            }}
            showPreview={showPreview}
          />
        )}
      </Modal>
    </>
  );
}
