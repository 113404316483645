// export const UPDATE_AVAILABLE_REQUESTS = 'UPDATE_AVAILABLE_REQUESTS';

export const RESET_APP = 'RESET_APP';
export const SEARCH_ORDER = 'SEARCH_ORDER';
export const UPDATE_MY_ASSIGNMENT_LIST = 'UPDATE_MY_ASSIGNMENT_LIST';
export const UPDATE_MY_SEARCH_ASSIGNMENT_LIST =
  'UPDATE_MY_SEARCH_ASSIGNMENT_LIST';
export const UPDATE_MY_ASSIGNMENT_FILTERS = 'UPDATE_MY_ASSIGNMENT_FILTERS';
export const RESET_MY_ASSIGNMENT_FILTER = 'RESET_MY_ASSIGNMENT_FILTER';
export const UPDATE_ASSIGNMENTS_RATINGS = 'UPDATE_ASSIGNMENTS_RATINGS';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_CALENDAR = 'UPDATE_USER_CALENDAR';
export const UPDATE_IS_SYNC_WITH_GOOGLE_CALENDAR =
  'UPDATE_IS_SYNC_WITH_GOOGLE_CALENDAR';
export const UPDATE_GOOGLE_CALENDAR_EVENT = 'UPDATE_GOOGLE_CALENDAR_EVENT';
export const UPDATE_USER_EVENTS = 'UPDATE_USER_EVENTS';
export const RESET_CALENDAR_EVENTS = 'RESET_CALENDAR_EVENTS';
export const UPDATE_DURATION = 'UPDATE_DURATION';
export const RESET_DURATION = 'RESET_DURATION';
export const RESET_DATE_CALENDAR = 'RESET_DATE_CALENDAR';

export const TOGGLE_LOADING_OVERLAY = 'TOGGLE_LOADING_OVERLAY';

export const AUTHENTICATE_RESOURCE_SUCCESS = 'AUTHENTICATE_RESOURCE_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_DISPLAY_KPI_REPORTS = 'SET_DISPLAY_KPI_REPORTS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// export const LOGOUT = 'LOGOUT';

export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';
export const UPDATE_AVAILABILITY_FAILURE = 'UPDATE_AVAILABILITY_FAILURE';
export const UPDATE_AVAILABILITY_RESET = 'UPDATE_AVAILABILITY_RESET';

export const UPDATE_CURRENT_AVAILABLE_FOR_INTERPRET_STATUS =
  'UPDATE_CURRENT_AVAILABLE_FOR_INTERPRET_STATUS';

export const UPDATE_AVAILABILITY_LIST = 'UPDATE_AVAILABILITY_LIST';

export const UPDATE_REASON_LIST = 'UPDATE_REASON_LIST';
export const UPDATE_COMPLAINT_REASON_LIST = 'UPDATE_COMPLAINT_REASON_LIST';

export const UPDATE_RATING = 'UPDATE_RATING';
export const UPDATE_TIMEREPORT = 'UPDATE_TIMEREPORT';
export const UPDATE_EXPENSES = 'UPDATE_EXPENSES';

export const UPDATE_COMPENSATION = 'UPDATE_COMPENSATION';
export const UPDATE_NEW_ASSIGNMENTS = 'UPDATE_NEW_ASSIGNMENTS';

export const UPDATE_LOCAL_STATUS_WORKING_OF_WORK_ASSIGNMENT =
  'UPDATE_LOCAL_STATUS_WORKING_OF_WORK_ASSIGNMENT';
export const UPDATE_LOCAL_STATUS_DONE_OF_WORK_ASSIGNMENT =
  'UPDATE_LOCAL_STATUS_DONE_OF_WORK_ASSIGNMENT';
export const UPDATE_LOCAL_TIME_SHEET_OF_WORK_ASSIGNMENT =
  'UPDATE_LOCAL_TIME_SHEET_OF_WORK_ASSIGNMENT';

export const UPDATE_JOBTIMER_MY_ASSIGNMENT = 'UPDATE_JOBTIMER_MY_ASSIGNMENT';
export const UPDATE_SKILLS = 'UPDATE_SKILLS';
export const UPDATE_SUBSTITUTE_SKILLS = 'UPDATE_SUB_SUBSTITUTE_SKILLS';
export const UPDATE_USER_CONTACT_PERSONS = 'UPDATE_USER_CONTACT_PERSONS';
export const UPDATE_SPECIFC_USER_CONTACT_PERSONS =
  'UPDATE_SPECIFC_USER_CONTACT_PERSONS';
export const DELETE_SPECIFC_USER_CONTACT_PERSONS =
  'DELETE_SPECIFC_USER_CONTACT_PERSONS';
export const UPDATE_USER_SERVICES = 'UPDATE_USER_SERVICES';

export const RESET_JOBTIMER = 'RESET_JOBTIMER';

export const UPDATE_SUBMITTED_ASSIGNMENT = 'UPDATE_SUBMITTED_ASSIGNMENT';

export const UPDATE_ASSIGNMENT_SUMMARY = 'UPDATE_ASSIGNMENT_SUMMARY';

export const UPDATE_ASSIGNMENT_DATE_RANGE = 'UPDATE_ASSIGNMENT_DATE_RANGE';

export const UPDATE_CONTACT_PERSONS = 'UPDATE_CONTACT_PERSONS';

export const UPDATE_RATING_REASONS = 'UPDATE_RATING_REASONS';
export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_USER_AGREEMENT = 'SET_USER_AGREEMENT';
export const SET_DATASOURCE = 'SET_DATASOURCE';
export const SET_REPORT_DATASOURCE = 'SET_REPORT_DATASOURCE';
export const SET_REPORT_DATASOURCE_RAW = 'SET_REPORT_DATASOURCE_RAW';
export const PUSH_DATASOURCE_TO_QUEUE = 'PUSH_DATASOURCE_TO_QUEUE';
export const PROCESS_DATASOURCE_QUEUE = 'PROCESS_DATASOURCE_QUEUE';
export const POP_DATASOURCE_TO_QUEUE = 'POP_DATASOURCE_TO_QUEUE';
export const GET_REPORTDATA = 'GET_REPORTDATA';

export const SET_CROSS_FILTER = 'SET_CROSS_FILTER';
export const REMOVE_CROSS_FILTER = 'REMOVE_CROSS_FILTER';

export const UPDATE_REPORT_DATE_PERIOD = 'UPDATE_REPORT_DATE_PERIOD';
export const UPDATE_REPORT_TABLES = 'UPDATE_REPORT_TABLES';
export const GET_MONTHLY_INTERPRETATIONS = 'GET_MONTHLY_INTERPRETATIONS';
export const GET_LATE_CANCELED_ASSIGNMENT = 'GET_LATE_CANCELED_ASSIGNMENT';
export const GET_TRAVEL_TIME_ALLOWANCES = 'GET_TRAVEL_TIME_ALLOWANCES';
export const GET_EMERGENCY_PREBOOKED_ASSIGNMENTS =
  'GET_EMERGENCY_PREBOOKED_ASSIGNMENTS';
export const GET_ASSIGNMENT_BY_UNITS = 'GET_ASSIGNMENT_BY_UNITS';
export const SET_DASHBOARD_DATERANGE = 'SET_DASHBOARD_DATERANGE';

export const UPDATE_DEVIATIONS = 'UPDATE_DEVIATIONS';
export const UPDATE_DEVIATION_FILTERS = 'UPDATE_DEVIATION_FILTERS';

export const GET_AVAILABLE_RESOURCE_AUTO_ASSIGN =
  'GET_AVAILABLE_RESOURCE_AUTO_ASSIGN';
export const GET_AVAILABLE_RESOURCE_AUTO_ASSIGN_PENDING =
  'GET_AVAILABLE_RESOURCE_AUTO_ASSIGN_PENDING';

export const CREATE_NEW_AUTO_ASSIGN_ORDER = 'CREATE_NEW_AUTO_ASSIGN_ORDER';

export const CREATE_NEW_AUTO_ASSIGN_ORDER_ERROR =
  'CREATE_NEW_AUTO_ASSIGN_ORDER_ERROR';

export const SET_BOOK_DIRECT_SERVICE_ID = 'SET_BOOK_DIRECT_SERVICE_ID';

export const UPDATE_NOTIFICATION_LIST = 'UPDATE_NOTIFICATION_LIST';

export const UPDATE_ASSIGNMENT_NOTIFICATION = 'UPDATE_ASSIGNMENT_NOTIFICATION';

export const GET_AUTO_ASSIGN_OPENING_HOURS = 'GET_AUTO_ASSIGN_OPENING_HOURS';

export const GET_REPORT_DATA = 'GET_REPORT_DATA';

export const UPDATE_SERVICES = 'UPDATE_SERVICES';
