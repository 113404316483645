import * as actionTypes from '../actions/ActionTypes';

const INITIAL_STATE = {
  dateFrom: '',
  dateTo: '',
  orderCount: '',
  Competence: [],
  SkillStatistics: [],
  InterpretationType: [],
  SuccessRateStatistics: [],
  AutoAssignStatistics: [],
  OrderChannel: [],
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;

    case actionTypes.GET_REPORT_DATA:
      return action.report;

    default:
      return state;
  }
};

export default app;
