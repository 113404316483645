import React from 'react';
import { Modal, TransvoiceButton } from '../../../Share/components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteContactPersons } from '../../actions';

const ContactPersonDelete = ({
  FirstName,
  LastName,
  showDeleteModal,
  onCloseModal,
  ContactPersonIdentifier,
  onDeleteContactPerson,
}) => {
  const handleDeleteContactPerson = identifier => {
    if (identifier) {
      onCloseModal();
      onDeleteContactPerson(identifier);
    }
  };

  return (
    <Modal
      onCloseClicked={onCloseModal}
      isVisible={showDeleteModal}
      className="tv-route-leaving-guard-modal tv-delete-user-modal"
      headerClassName="tv-cancelassignment_modal__header"
    >
      <div className="tv-route-leaving-guard-modal-area">
        <div className="tv-route-leaving-guard-modal-area-title">
          Radera användare?
        </div>
        <div
          className="tv-route-leaving-guard-modal-area-text mt-3 mb-4"
          style={{ width: '284px' }}
        >
          {`Användaren ${FirstName || ''} ${
            LastName || ''
          } kommer att raderas. Detta går inte att
      ångra.`}
        </div>
      </div>
      <div className="tv-route-leaving-guard-modal__buttons tv-display-flex">
        <TransvoiceButton
          type="line-primary"
          onClick={onCloseModal}
          text="Avbryt"
          className="tv-route-leaving-guard-modal-area-backBtn"
        />
        <TransvoiceButton
          type="secondary"
          text="Radera"
          onClick={() => {
            handleDeleteContactPerson(ContactPersonIdentifier);
          }}
          className="tv-route-leaving-guard-modal-area-submitBtn"
        />
      </div>
    </Modal>
  );
};

ContactPersonDelete.propTypes = {
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  ContactPersonIdentifier: PropTypes.string,
  showDeleteModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

ContactPersonDelete.defaultProps = {
  FirstName: '',
  LastName: '',
  ContactPersonIdentifier: '',
  showDeleteModal: false,
  onCloseModal: () => {},
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDeleteContactPerson: identifier => {
    dispatch(deleteContactPersons(identifier));
  },
});

export default connect(null, mapDispatchToProps)(ContactPersonDelete);
