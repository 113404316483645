/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { iconForTheServiceType, isIE } from '../../../Share/utils';
import { Modal, Translate, TransvoiceButton } from '../../../Share/components';
import moment from 'moment';

function SuccessMessage({
  visible,
  onClose,
  assignmentDetails,
  onContinueBooking,
  deviationNumber,
  mode,
}) {
  const [copyText, setCopyText] = useState(false);

  const deviationSuccessMessage = () => (
    <div className="tv-deviation-message">
      <div className="tv-deviation-message-title">
        <Translate content="reportQuality.deviationSuccessTitle" />
      </div>
      <div className="tv-deviation-message-paragraph">
        <Translate content="reportQuality.deviationSuccessMessagePart1" />
      </div>
      <div className="tv-deviation-message-paragraph">
        <Translate content="reportQuality.deviationSuccessMessagePart2" />
      </div>
    </div>
  );

  const copyToClipboard = id => {
    navigator.clipboard.writeText(id);
    setCopyText(true);
  };

  return (
    <>
      <div className="d-flex flex-column tv-workassignment__modal-body">
        <div className="d-flex flex-column m-1 m-md-5 h-100 justify-content-around">
          <div>
            <h4 className="tv-headline-1 my-1 text-center">
              {mode === 'deviation'
                ? ''
                : mode === 'edit'
                ? 'Din bokning är ändrad'
                : 'Din bokning är skapad'}
            </h4>
            <div
              className="d-flex justify-content-center mb-2 text-center tv-title-text"
              style={{ color: '#74737B' }}
            >
              {mode === 'deviation' ? (
                deviationSuccessMessage()
              ) : assignmentDetails[0].isNewOrder ? (
                ` Din första bokning är avbokad och en ny är skapad.
Ditt nya bokningsnummer är ${assignmentDetails[0].orderNumber}`
              ) : (
                <>
                  {/* Ditt {mode === 'edit' ? '' : 'nya'} bokningsnummer är&nbsp; */}
                  <div
                    onClick={() =>
                      copyToClipboard(assignmentDetails[0].orderNumber)
                    }
                    style={{ marginTop: '10px', cursor: 'pointer' }}
                    title={copyText ? 'Kopierade!' : 'Kopiera bokningsnummer'}
                  >
                    <span className="copy-to-clipboard">
                      {assignmentDetails[0].orderNumber}
                    </span>
                    <i
                      className={`fa ${copyText ? 'fa-check' : 'fa-copy'}`}
                      style={{
                        marginLeft: '8px',
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: '#873bff',
                      }}
                      aria-hidden="true"
                    />
                    {/* <img src="/images/copy-to-clipboard.jpg" alt="pre-book" /> */}
                  </div>
                </>
              )}
            </div>
            {mode !== 'deviation' && (
              <div className="tv-booking-success">
                <div className="tv-booking-success-item">
                  <i className="fa fa-globe" aria-hidden="true" />
                  <div>{assignmentDetails[0]?.language?.SkillName}</div>
                </div>
                <div className="tv-booking-success-item">
                  <i
                    className={iconForTheServiceType(
                      assignmentDetails[0]?.typeOfAssignment?.ServiceName,
                    )}
                    aria-hidden="true"
                  />
                  <div>
                    {assignmentDetails[0]?.typeOfAssignment?.ServiceName}
                  </div>
                </div>
                <div className="tv-booking-success-item">
                  <i className="fa fa-calendar" aria-hidden="true" />
                  <div style={{ textTransform: 'lowercase' }}>
                    {moment(assignmentDetails[0].dateTimeOrderFrom).format(
                      'DD MMMM',
                    )}
                  </div>
                </div>
                <div className="tv-booking-success-item">
                  <i className="fa fa-clock" aria-hidden="true" />
                  <div>{`${moment(
                    assignmentDetails[0].dateTimeOrderFrom,
                  ).format('HH:mm')} - ${moment(
                    assignmentDetails[0].dateTimeOrderTo,
                  ).format('HH:mm')}`}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="tv-workassignment__detail-links ml-2" />
        <div
          className="tv-workassignment__detail-buttons tv-display-flex"
          style={{
            justifyContent:
              mode === 'assignment' || mode === 'edit'
                ? 'space-between'
                : 'center',
          }}
        >
          <TransvoiceButton
            type="secondary"
            className="tv-single-assign-form__button-preview"
            buttonType="button"
            text="Klar"
            onClick={onClose}
          />
          {(mode === 'assignment' || mode === 'edit') && (
            <TransvoiceButton
              type="line-primary"
              buttonType="button"
              className="w-50 ml-1"
              text="Fortsätt boka"
              onClick={e => {
                onContinueBooking();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

SuccessMessage.propTypes = {
  mode: PropTypes.string,
  deviationNumber: PropTypes.number,
  onContinueBooking: PropTypes.func,
  onClose: PropTypes.func,
};
SuccessMessage.defaultProps = {
  mode: 'assignment',
  onContinueBooking: () => {},
  onClose: () => {},
  deviationNumber: null,
};

export default SuccessMessage;
