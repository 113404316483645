import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-form-validator-core';
import classNames from 'classnames';
import { DropDownList as DropDown } from '@progress/kendo-react-dropdowns';
import IfComponent from '../IfComponent';
import { TOP_SKILLS } from '../../constants';

const propTypes = {
  errorMessages: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  validators: PropTypes.arrayOf(PropTypes.string),
  validatorListener: PropTypes.func,
  value: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  optionWithAction: PropTypes.shape({}),
  className: PropTypes.string,
  defaultItem: PropTypes.shape({}),
  popupHeight: PropTypes.number,
  rest: PropTypes.shape({}),
  filterable: PropTypes.bool,
  onFilterChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  errorMessages: [],
  optionWithAction: {
    actionValue: '',
    actionText: '',
    visible: false,
  },
  defaultItem: { name: 'Välj', value: '' },
  validators: ['matchRegexp:(.*)'],
  validatorListener: () => {},
  value: '',
  options: [],
  className: '',
  footer: null,
  rest: {},
  popupHeight: 200,
  filterable: false,
  onFilterChange: () => {},
  extraPopupSettings: {},
  disabled: false,
};

class DropdownList extends ValidatorComponent {
  state = {};
  render() {
    const {
      errorMessages,
      validators,
      validatorListener,
      optionWithAction,
      value,
      options,
      defaultItem,
      className,
      footer,
      popupHeight,
      filterable,
      onFilterChange,
      extraPopupSettings,
      disabled,
      ...rest
    } = this.props;

    const itemRender = (li, itemProps) => {
      const { name, highlight } = itemProps?.dataItem;
      if (TOP_SKILLS.includes(name) && highlight) {
        return <div className="k-option">{li}</div>;
      }
      return li;
    };

    const { isValid } = this.state;
    const controlClassName = classNames({
      'tv-form__control': true,
      'tv-form__control--error': !isValid && this.getErrorMessage().length,
      [className]: !!className,
    });
    return (
      <React.Fragment>
        <DropDown
          ref={r => {
            this.input = r;
          }}
          defaultItem={defaultItem}
          className={controlClassName}
          name={rest.name}
          data={options}
          textField="name"
          dataItemKey="value"
          value={value}
          onChange={rest.onChange}
          footer={footer}
          popupSettings={{ ...extraPopupSettings, height: popupHeight }}
          itemRender={itemRender}
          filterable={filterable}
          onFilterChange={onFilterChange}
          disabled={disabled}
        />

        <IfComponent
          condition={this.getErrorMessage().length > 0}
          whenTrue={
            <span className="tv-form__error">
              {typeof isValid === 'boolean' && !isValid
                ? this.getErrorMessage()
                : ''}
            </span>
          }
        />
      </React.Fragment>
    );
  }
}

DropdownList.propTypes = propTypes;
DropdownList.defaultProps = defaultProps;

export default DropdownList;
