import { toast } from 'react-toastify';
import { sortBy } from 'lodash/core';
import * as mailApi from '../api/mailApi';
import * as api from '../api';

import { Translate } from '../../Share/components';
import {
  transvoiceLog,
  logApiLevel,
  showToastMessage,
} from '../../Share/utils';

import {
  UPDATE_ARTICLE,
  UPDATE_REASON_LIST,
  UPDATE_SKILLS,
  UPDATE_COMPLAINT_REASON_LIST,
  UPDATE_DEVIATIONS,
  UPDATE_DEVIATION_FILTERS,
  UPDATE_NOTIFICATION_LIST,
  UPDATE_ASSIGNMENT_NOTIFICATION,
  UPDATE_SUBSTITUTE_SKILLS,
  RESET_JOBTIMER,
  UPDATE_JOBTIMER_MY_ASSIGNMENT,
} from './ActionTypes';
import { showLoadingOverlay, hiddenLoadingOverlay } from './app';
import { broadCast } from './eventBus';
import {
  currentDate,
  getNearest5MinutesInterval,
} from '../../Share/utils/dateUtil';
import moment from 'moment';
import { fetchServices } from './user';

// getArticles use for get id of location: phone, onSite
export function fetchArticles() {
  return (dispatch, getState) => {
    api
      .getArticles()
      .then(response => {
        if (response.data && !response.data.Errors) {
          let phoneTypeId = '';
          let onSiteTypeId = '';

          response.data.Articles.forEach(article => {
            if (article.ArticleName === 'Telefontolkning') {
              phoneTypeId = article.ArticleIdentifier;
            }
            if (article.ArticleName === 'Kontakttolkning') {
              onSiteTypeId = article.ArticleIdentifier;
            }
          });

          transvoiceLog(
            `Articles response phone: ${phoneTypeId} onSite: ${onSiteTypeId}`,
          );
          dispatch({
            type: UPDATE_ARTICLE,
            phone: phoneTypeId,
            onSite: onSiteTypeId,
          });
        } else {
          const errorResponse =
            response.data && response.data.Errors ? response.data.Errors : {};
          throw new Error(`getArticle error ${JSON.stringify(errorResponse)}`);
        }
      })
      .catch(error => {
        transvoiceLog(error, logApiLevel.error);
      });
  };
}

export function getReasonList() {
  return async (dispatch, getState) => {
    try {
      const reasonList = [];
      const response = await api.getCancellationReasons();

      const cancellationReasons = response.data.OrderCancellationReasons;
      cancellationReasons.forEach(item => {
        reasonList.push({
          identifier: item.OrderCancellationReasonIdentifier,
          content: item.OrderCancellationReason,
        });
      });

      dispatch({
        type: UPDATE_REASON_LIST,
        reasonList,
      });
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
    }
  };
}

export function getComplaintReasonList() {
  return async (dispatch, getState) => {
    try {
      const response = await api.getComplaintReasonDeliveryRequest();
      if (response.data && !response.data.Errors) {
        const complaintReasons = response.data.ComplaintReasons;
        dispatch({
          type: UPDATE_COMPLAINT_REASON_LIST,
          complaintReasons,
        });
      } else {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};
        throw new Error(
          `GetComplaintReasonDelivery error ${JSON.stringify(errorResponse)}`,
        );
      }
    } catch (error) {
      transvoiceLog(error);
    }
  };
}

export function fetchSkills() {
  return async dispatch => {
    try {
      const response = await api.getSkills();
      if (response.data && !response.data.Errors) {
        const sortedSkills = sortBy(response.data.Skills, 'SkillName');
        const substituteSkills = {};

        if (sortedSkills && sortedSkills.length > 0) {
          const params = {
            language: sortedSkills[0].SkillIdentifier,
            startTime:
              moment().hours() > 8
                ? getNearest5MinutesInterval().add(5, 'minutes')
                : moment().hours(8).minutes(0).seconds(0),
            endTime:
              moment().hours() > 8
                ? getNearest5MinutesInterval().add(35, 'minutes')
                : moment().hours(8).minutes(0).seconds(0).add(30, 'minutes'),
          };
          dispatch(fetchServices(params));
        }

        sortedSkills.forEach(sortedSkill => {
          if (sortedSkill.SubstituteSkills) {
            const substituteSkillsForSkill = sortedSkill.SubstituteSkills.map(
              subSkill => {
                const skillIdentifier = subSkill.SkillIdentifier;
                const skillFound = sortedSkills.find(
                  skill => skill.SkillIdentifier === skillIdentifier,
                );
                return {
                  SkillIdentifier: skillIdentifier,
                  SkillName: skillFound.SkillName,
                };
              },
            );
            substituteSkills[sortedSkill.SkillIdentifier] =
              substituteSkillsForSkill;
          }
        });

        dispatch({
          type: UPDATE_SKILLS,
          skills: sortedSkills,
        });
        dispatch({
          type: UPDATE_SUBSTITUTE_SKILLS,
          substituteSkills,
        });
      } else {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};
        throw new Error(`getSkill error ${JSON.stringify(errorResponse)}`);
      }
    } catch (error) {
      transvoiceLog(error);
    }
  };
}

export function getAttachment(attachmentIdentifier) {
  return (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;

    api
      .getAttachmentCustomer(token, attachmentIdentifier)
      .then(response => {
        if (response.data && !response.data.Errors) {
          const contentType = response.data.Attachment.AttachmentContentType; // application/pdf, application/doc ...
          const base64Content = response.data.Attachment.AttachmentFile;
          const linkSource = `data:${contentType};base64,${base64Content}`;
          const fileName = response.data.Attachment.AttachmentFilename; // foo.pdf, bar.doc ...

          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          transvoiceLog(response.data.Errors, logApiLevel.error);
        }
      })
      .catch(err => {
        transvoiceLog(err, logApiLevel.error);
      });
  };
}

export function getDeviations(data = {}, callback, setdata = true) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      data.Token = state.authentication.token;
      dispatch(showLoadingOverlay());
      const response = await api.getDeviations(data);
      if (response.data && !response.data.Errors) {
        const { Deviations } = response.data;
        if (setdata) {
          dispatch({
            type: UPDATE_DEVIATIONS,
            deviations: Deviations,
          });
        }
        const returnData = {
          data: response.data.Deviations ? response.data.Deviations : {},
          IsSuccess: !!response.data.IsSuccess,
        };
        if (callback && typeof callback === 'function') callback(returnData);
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function createDeviation(data, callback) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      data.Token = state.authentication.token;
      dispatch(showLoadingOverlay());
      const response = await api.createDeviation(data);
      if (response.data && !response.data.Errors) {
        const returnData = {
          DeviationNumber: response.data.DeviationNumber
            ? response.data.DeviationNumber
            : '',
          IsSuccess: !!response.data.IsSuccess,
        };
        if (callback && typeof callback === 'function') callback(returnData);
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function updateDeviationFilters(filterType, value) {
  return dispatch =>
    dispatch({
      type: UPDATE_DEVIATION_FILTERS,
      filterType,
      value,
    });
}

export function sendDeviationEmail(data, callback) {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoadingOverlay());
      const state = getState();

      const { customerName, customerNumber } = state.user.profile;

      const subject = data.ComplaintReasonDeliveryName;
      const content = `${data.DeviationDescription}
        <br/> <br/>
        <strong>Customer Information</strong> <br />
        Customer name: ${customerName} <br/>
        Customer number: ${customerNumber}
        <br/> <br/>
        ${data.OrderNumber ? `Order Number: ${data.OrderNumber} <br/>` : ''}
        ${
          data.ContactPersonData
            ? `Contact Person: ${data.ContactPersonData.name} <br/>`
            : ''
        }
        ${
          data.ReplyEmail
            ? `Reply to me via <a href='mailto:${data.ReplyEmail}'>${data.ReplyEmail}</a>.`
            : ''
        }
      `;
      await mailApi.sendMail(subject, content);

      // show success message
      const returnData = {};
      returnData.IsSuccess = true;
      if (callback && typeof callback === 'function') callback(returnData);
    } catch (error) {
      toast.error(`${Translate({ content: 'error.commonError' })}`);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function getNotificationList(
  orderIdentifier,
  isUnreadOnly = false,
  datetimeSentFrom = null,
  successCallback = () => {},
) {
  return async (dispatch, getState) => {
    try {
      let notificationList = [];
      const state = getState();
      const response = await api.getNotifications(
        state.authentication.token,
        orderIdentifier,
        isUnreadOnly,
        datetimeSentFrom,
      );

      successCallback(response);
      notificationList = response?.data?.Notifications || [];

      if (orderIdentifier) {
        dispatch({
          type: UPDATE_ASSIGNMENT_NOTIFICATION,
          notificationList,
        });
      } else {
        dispatch({
          type: UPDATE_NOTIFICATION_LIST,
          notificationList,
        });
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
    }
  };
}

export function setNotificationReadStatus(
  notificationIdentifier,
  IsRead = false,
  callback = () => {},
) {
  return async (dispatch, getState) => {
    try {
      let notificationList = [];
      const state = getState();
      const response = await api.setNotificationReadStatus(
        state.authentication.token,
        notificationIdentifier,
        IsRead,
      );

      if (response.data && !response.data.Errors) {
        notificationList = state.notification.notificationList || [];
        notificationList = notificationList.map(item => {
          if (item.NotificationIdentifier === notificationIdentifier) {
            return { ...item, IsRead };
          }
          return item;
        });

        dispatch({
          type: UPDATE_NOTIFICATION_LIST,
          notificationList,
        });
        const timeAssignment = currentDate();
        dispatch({
          type: RESET_JOBTIMER,
        });

        dispatch({
          type: UPDATE_JOBTIMER_MY_ASSIGNMENT,
          timeMyAssignment: timeAssignment.subtract(1, 'hours'),
        });

        broadCast('SuccessFullReadNotification', {
          NotificationIdentifier: notificationIdentifier,
        });

        if (callback && typeof callback === 'function') callback();
        // showToastMessage('success', Translate({ content: 'notification.notificationReadStatusChangedMessage' }),);
      }
    } catch (error) {
      transvoiceLog(error, logApiLevel.error);
    }
  };
}
