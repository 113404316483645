import React, { Component, useEffect, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  getDashboardData,
  updateUserCalendar,
  getNotificationList,
  getAttachment,
  checkUpdateCalendarEvent,
  resetCalendar,
  setOrderRating,
  onUpdateNewAssignmentsTemp,
  updateMyAssignmentFilters,
  GetRatingReasons,
  getDeviations,
  autoAssignOpeningHours,
  setNotificationReadStatus,
} from '../../actions';
import {
  Calendar,
  Translate,
  ReleaseMessage,
  RatingSummary,
  LeftDrawerSlide,
  AssignmentListBox,
  RouteLeavingGuard,
  RouteLeavingModal,
} from '../../../Share/components';
import AssignmentDetail from '../AssignmentDetail';
import NotificationDetail from '../NotificationDetail';
import './style.scss';
import { status, FILTER_TYPES, PAGE_TYPES } from '../../../Share/constants';
import history from '../../../Share/utils/history';
import { QuickOrder } from '../QuickOrder';
import StatusFilter from '../StatusFilter';
import { subscribe, unSubscribe } from '../../actions/eventBus';
import {
  convertAssignmentToOrder,
  generateEditOrderDateSession,
  isIE,
} from '../../../Share/utils';
import { currentDate } from '../../../Share/utils/dateUtil';
import OrderSearchBar from './OrderSearchBar';
import { BookNowModal } from '../../components';
import Banner from './Banner';

const propTypes = {
  availableRequests: PropTypes.shape({}),
  dateRange: PropTypes.shape({}),
  onGetDashboardData: PropTypes.func,
  onUpdateUserCalendar: PropTypes.func,
  onGetNotifications: PropTypes.func,
  onGetAttachment: PropTypes.func,
  assignmentDetail: PropTypes.shape({}),
  assignmentList: PropTypes.arrayOf(PropTypes.shape({})),
  assignmentNotifications: PropTypes.arrayOf(PropTypes.shape({})),
  onCheckUpdateCalendarEvent: PropTypes.func,
  onResetCalendar: PropTypes.func,
  onOrderRated: PropTypes.func.isRequired,
  onSwitchToDetailOrder: PropTypes.func,
  mapOnFilterDeviation: PropTypes.func,
  getAutoAssignOpeningHours: PropTypes.func,
  onSetNotificationReadStatus: PropTypes.func,
};

const defaultProps = {
  availableRequests: {
    count: 0,
    items: [],
  },
  onCheckUpdateCalendarEvent: () => {},
  onResetCalendar: () => {},
  onGetDashboardData: () => {},
  onUpdateUserCalendar: () => {},
  onGetNotifications: () => {},
  onGetAttachment: () => {},
  // onOrderRated: () => {},
  assignmentDetail: {},
  dateRange: {},
  assignmentList: [],
  assignmentNotifications: [],
  onSwitchToDetailOrder: () => {},
  mapOnFilterDeviation: () => {},
  getAutoAssignOpeningHours: () => {},
  onSetNotificationReadStatus: () => {},
};

const INITAL_STATES = {
  showDetail: false,
  showNotification: false,
  showQuickOrder: false,
  quickOrderDate: null,
  showStatusFilter: false,
  showAssigmentList: false,
  showInterpretNow: false,
  assignmentDetail: {},
  selectedDateInfo: {
    assignmentList: [],
    day: moment(),
  },
  sameDateAssignments: [],
  disableNextButton: false,
  disablePreviousButton: false,
  windowWidth: window.innerWidth,
  isDirty: false,
  visibleRouteLeavingModal: false,
  isWeeklyViewData: false,
  showBookDirectModal: false,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
  }

  componentDidMount() {
    this.props.onResetCalendar();
    this.props.onGetDashboardData();
    this.props.getRateReasons();
    window.addEventListener('resize', () => {
      this.setHeightVariable();
    });
    this.orderSuccess = subscribe('SuccessFullOrderCreation', () => {
      // this.props.onResetCalendar();
      this.props.onGetDashboardData();
    });
    this.cancelevent = subscribe('SuccessFullCancellOrder', response => {
      this.setState({
        ...this.state,
        showAssignemntList: false,
        showAssigmentList: false,
        showDetail: false,
      });
      this.props.onResetCalendar();
      this.props.onGetDashboardData();
    });
    this.readSuccess = subscribe('SuccessFullReadNotification', response => {
      this.setState({
        ...this.state,
        showAssignemntList: false,
        showAssigmentList: false,
        // showDetail: false,
      });
      this.props.onResetCalendar();
      this.props.onGetDashboardData();
    });
  }
  componentWillUnmount() {
    unSubscribe('SuccessFullCancellOrder', this.cancelevent);
    unSubscribe('SuccessFullOrderCreation', this.orderSuccess);
    unSubscribe('SuccessFullReadNotification', this.readSuccess);
  }
  setHeightVariable() {
    const vh = document.body.scrollHeight;

    const rightWrapper = document.querySelector('.tv-right-pannel-wrapper');
    if (rightWrapper) {
      rightWrapper.style.setProperty('--vh', `${vh}px`);
    }
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  onShowDetail = item => {
    this.setState({
      ...this.state,
      sameDateAssignments: [],
      disableNextButton: false,
      disablePreviousButton: false,
      showDetail: true,
      showNotification: false,
      assignmentDetail: item,
      isEventDetail: false,
      activeAssignmentId: '',
    });
  };

  onTabChange = () => {
    this.setState({
      showNotification: false,
      showDetail: false,
      showAssigmentList: false,
    });
  };

  handleEventClick = (type, id) => {
    const { assignmentList } = this.props;
    const assignmentDetail = assignmentList.find(x => x.OrderIdentifier === id);
    const { DatetimeFrom } = assignmentDetail;
    const Date = moment(DatetimeFrom).format('DD-MM-YYYY');

    const sameDateAssignments = assignmentList.filter(
      item => moment(item.DatetimeFrom).format('DD-MM-YYYY') === Date,
    );
    const Index = sameDateAssignments.findIndex(
      x => x.OrderIdentifier === assignmentDetail.OrderIdentifier,
    );

    this.setState({
      ...this.state,
      sameDateAssignments,
      assignmentDetail,
      // showDetail: true,
      isEventDetail: true,
      // showQuickOrder: false,
      activeAssignmentId: assignmentDetail.OrderIdentifier,
      disableNextButton: Index === sameDateAssignments.length - 1,
      disablePreviousButton: Index === 0,
      isWeeklyViewData: true,
    });

    const { isDirty } = this.state;
    if (isDirty) {
      this.setState({ visibleRouteLeavingModal: true });
    } else {
      this.setState({ showDetail: true });
    }
  };

  redirectToTimeSheet = () => {
    const { assignmentDetail } = this.state;

    history.push({
      pathname: '/time-sheet',
      state: {
        from: this.props.location,
        assignmentDetail,
      },
    });
  };

  handleClickTimeReport = () => {
    this.redirectToTimeSheet();
  };

  handleDrawerClose = () => {
    this.setState({
      showDetail: false,
    });
  };

  handleQuickOrderDrawerClose = () => {
    this.setState({
      showQuickOrder: false,
    });
  };

  handleStatusFilterDrawerClose = () => {
    this.setState({
      showStatusFilter: false,
    });
  };
  handleAssignmentListDrawerClose = () => {
    this.setState({
      showAssigmentList: false,
    });
  };

  handleDrawerNextPrevious = value => {
    const { activeAssignmentId, sameDateAssignments } = this.state;
    this.setState({
      ...this.state,
      disableNextButton: false,
      disablePreviousButton: false,
    });

    if (sameDateAssignments.length && activeAssignmentId) {
      const Index = sameDateAssignments.findIndex(
        x => x.OrderIdentifier === activeAssignmentId,
      );

      if (
        Index + value <= sameDateAssignments.length - 1 &&
        Index + value >= 0
      ) {
        const assignmentDetail = sameDateAssignments[Index + value];
        this.setState({
          ...this.state,
          assignmentDetail,
          showDetail: true,
          isEventDetail: true,
          activeAssignmentId: assignmentDetail.OrderIdentifier,
          disableNextButton: Index + value === sameDateAssignments.length - 1,
          disablePreviousButton: Index + value === 0,
        });
      }
    }
  };

  handleChangeEventCalendar = event => {
    const { onUpdateUserCalendar, onCheckUpdateCalendarEvent } = this.props;
    onUpdateUserCalendar(event);
    onCheckUpdateCalendarEvent();
  };

  handleFilter = value => {
    const { updateMyAssignmentFilter } = this.props;
    updateMyAssignmentFilter(FILTER_TYPES.STATUS_FILTER, value);
  };

  resetQuickOrderDate = () => {
    this.setState({
      quickOrderDate: null,
    });
  };

  handleCopyOrder = assignment => {
    const { skills } = this.props;
    const convertedOrder = convertAssignmentToOrder(
      assignment,
      this.props.profile,
      skills,
    );
    this.props.onSwitchToDetailOrder(convertedOrder);
    this.RouteLeavingGuardHandler(true);

    this.setState({
      showQuickOrder: true,
      showDetail: false,
      showAssigmentList: false,
    });
  };

  handleEditOrder = assignment => {
    const { skills } = this.props;
    const convertedOrder = convertAssignmentToOrder(
      assignment,
      this.props.profile,
      skills,
    );

    this.props.onSwitchToDetailOrder({
      ...convertedOrder,
      dateRange: generateEditOrderDateSession(
        assignment.DatetimeFrom,
        assignment.DatetimeTo,
      ),
      isEdit: true,
      OrderIdentifier: assignment.OrderIdentifier,
      OrderNumber: assignment.OrderNumber,
    });
    this.RouteLeavingGuardHandler(true);

    this.setState({
      showQuickOrder: true,
      showDetail: false,
      showAssigmentList: false,
    });
  };

  RouteLeavingGuardHandler = value => {
    this.setState({ isDirty: value });
  };
  RouteLeavingModalHandler = (eventList, dateItem) => {
    const { isDirty } = this.state;
    this.setState({
      // showAssigmentList: true,
      showDetail: false,
      // showQuickOrder: false,
      selectedDateInfo: {
        day: dateItem,
        assignmentList: eventList,
      },
    });
    if (isDirty) {
      this.setState({ visibleRouteLeavingModal: true });
    } else {
      this.setState({ showAssigmentList: true });
    }
  };

  handlerNotification = notifications => {
    const { onGetNotifications } = this.props;
    const { assignmentDetail } = this.state;
    onGetNotifications(assignmentDetail.OrderIdentifier, () => {
      this.setState({
        showNotification: true,
      });
    });
  };

  handleBookDirectModal = () => {
    const { getAutoAssignOpeningHours } = this.props;
    getAutoAssignOpeningHours({
      DateFrom: moment().format('YYYY-MM-DD'),
      DateTo: moment().format('YYYY-MM-DD'),
    });
    this.setState({
      ...this.state,
      showBookDirectModal: !this.state.showBookDirectModal,
    });
  };

  render() {
    const {
      assignmentNotifications,
      onOrderRated,
      profile,
      statusFilter,
      mapOnFilterDeviation,
      bookDirectOpeningHours,
      onSetNotificationReadStatus,
    } = this.props;
    const { customerName } = profile;
    const {
      showDetail,
      showNotification,
      showQuickOrder,
      quickOrderDate,
      showStatusFilter,
      assignmentDetail,
      showAssigmentList,
      sameDateAssignments,
      disableNextButton,
      disablePreviousButton,
      selectedDateInfo,
      isDirty,
      visibleRouteLeavingModal,
      isWeeklyViewData,
    } = this.state;
    const visibleNextPreviousButtons = sameDateAssignments.length > 1;
    const showSidePanel = !(
      showStatusFilter ||
      showQuickOrder ||
      showAssigmentList ||
      showDetail
    );
    return (
      <React.Fragment>
        <RouteLeavingGuard
          when={isDirty}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            if (isDirty) {
              return true;
            }
            return false;
          }}
        />
        <RouteLeavingModal
          isVisible={visibleRouteLeavingModal}
          onCloseClicked={() => {
            this.setState({ visibleRouteLeavingModal: false });
          }}
          onOverlayClick={() => {
            this.setState({ visibleRouteLeavingModal: false });
          }}
          onConfirmClick={() => {
            if (isWeeklyViewData) {
              this.setState({
                isWeeklyViewData: false,
                showDetail: true,
                showQuickOrder: false,
                visibleRouteLeavingModal: false,
              });
            } else {
              this.setState({
                showAssigmentList: true,
                showQuickOrder: false,
                visibleRouteLeavingModal: false,
              });
            }
            this.RouteLeavingGuardHandler(false);
          }}
        />
        <div className="tv-workassignment-calendar__wrapper">
          <ReleaseMessage />

          <div className="tv-display-mobile-show tabs">
            <div className="tk-calendar__main-title mt-auto d-flex">
              <div>
                <div style={{ fontSize: '15px' }}>
                  {Translate({ content: 'dashboard.welcome' })}
                </div>{' '}
                {customerName}{' '}
              </div>
              <CurrentTime className="align-self-end" />
            </div>

            <RatingSummary />
          </div>
          <div className="tv_dashboard__main-panel w-100 row mt-3 mt-md-0 mx-0">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 px-0 pr-xl-3 tv-display-mobile-hide tabs d-flex flex-column"
              style={{
                visibility: showSidePanel
                  ? 'initial'
                  : isIE
                  ? 'initial'
                  : 'hidden',
              }}
            >
              {/* <SearchBox
                  placeholder={Translate({
                    content: 'myfilter.search',
                  })}
                  onBlur={this.searchHandler}
              /> */}
              <OrderSearchBar onEventClick={this.handleEventClick} />
              <StatusFilter
                onClick={this.handleFilter}
                selectedFilter={statusFilter}
              />
              <RatingSummary />
              <Banner />
              <div className="tk-calendar__main-title mt-auto">
                <div>
                  {Translate({ content: 'dashboard.welcome' })} {customerName}{' '}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 px-0 pl-1 mb-1">
              <Calendar
                onTabChange={this.onTabChange}
                onEventClick={this.handleEventClick}
                selectedEvent={assignmentDetail}
                onUpdateUserCalendar={this.handleChangeEventCalendar}
                onAssignmentSummuryPillClicked={(eventList, dateItem) => {
                  this.RouteLeavingModalHandler(eventList, dateItem);

                  // this.setState({
                  //   showAssigmentList: true,
                  //   showDetail: false,
                  //   showQuickOrder: false,
                  //   selectedDateInfo: {
                  //     day: dateItem,
                  //     assignmentList: eventList,
                  //   },
                  // });
                }}
                selectedDate={showAssigmentList ? selectedDateInfo.day : null}
                quickOrderDateShow={showQuickOrder}
                onCreateOrder={date => {
                  this.RouteLeavingGuardHandler(true);

                  this.setState({
                    quickOrderDate: date,
                    showQuickOrder: true,
                    showAssigmentList: false,
                    showDetail: false,
                  });
                }}
                onFilter={() => {
                  this.setState({
                    showStatusFilter: true,
                  });
                }}
                displayListItemContactPerson
                visiblePrefix={false}
                filterParamater={statusFilter}
                onCreateDirectBooking={this.handleBookDirectModal}
                currentTime={({ className }) => (
                  <CurrentTime className={className} />
                )}
              />
            </div>
          </div>
          <LeftDrawerSlide
            isVisible={showStatusFilter}
            hideExternalScroll
            onClose={() => {
              this.setState({
                showStatusFilter: false,
              });
            }}
          >
            <div className="tv-drawer__title-mobile">
              {Translate({ content: 'dashboard.filterAssignments' })}
            </div>
            <OrderSearchBar
              key="mobile"
              onBlur={() => {
                this.setState({
                  showStatusFilter: false,
                });
              }}
              onEventClick={this.handleEventClick}
            />
            <StatusFilter
              onClick={this.handleFilter}
              selectedFilter={statusFilter}
            />
            <div className="d-flex tv-drawer_buttons">
              <button
                className="tv-buttons__element tv-buttons__element--line-primary mr-2"
                onClick={this.handleStatusFilterDrawerClose}
              >
                {Translate({ content: 'dashboard.cancel' })}
              </button>
              <button
                className="tv_dashboard__add-button"
                onClick={this.handleStatusFilterDrawerClose}
              >
                {Translate({ content: 'dashboard.filter' })}
              </button>
            </div>
          </LeftDrawerSlide>

          {showQuickOrder && (
            <QuickOrder
              onResetDate={this.resetQuickOrderDate}
              date={quickOrderDate}
              onClose={() => {
                this.handleQuickOrderDrawerClose();
                this.props.onSwitchToDetailOrder({});
              }}
              showQuickOrder={showQuickOrder}
              RouteLeavingGuardHandler={this.RouteLeavingGuardHandler}
            />
          )}

          <LeftDrawerSlide
            isVisible={showAssigmentList}
            hideClose
            hideExternalScroll
            onClose={() => {
              this.setState({
                showAssigmentList: false,
              });
            }}
            className="tk-assignment-detail__drawer"
          >
            <AssignmentListBox
              assignmentList={selectedDateInfo.assignmentList}
              onEventClick={this.handleEventClick}
              newBooking={e => {
                this.setState({
                  showAssigmentList: false,
                  showQuickOrder: true,
                });
              }}
              dateItem={selectedDateInfo.day}
              onClose={e => this.handleAssignmentListDrawerClose(e)}
              childButton={
                <div className="d-flex tv-drawer_buttons list_drawer">
                  <button
                    className="tv-buttons__element tv-buttons__element--line-primary mr-2"
                    onClick={this.handleAssignmentListDrawerClose}
                  >
                    <Translate content="general.close" />
                  </button>

                  {selectedDateInfo.day > currentDate() && (
                    <button
                      className="tv_dashboard__add-button tv-display-mobile-show tabs"
                      onClick={e => {
                        this.setState({
                          showAssigmentList: false,
                          showQuickOrder: true,
                          quickOrderDate: selectedDateInfo.day,
                        });
                      }}
                    >
                      <i className="fa fa-plus mr-2" />
                      <Translate content="calendar.newBooking" />
                    </button>
                  )}
                </div>
              }
            />
          </LeftDrawerSlide>

          {showNotification && (
            <div>
              Notification
              <NotificationDetail
                isVisible={showNotification}
                onClose={() => {
                  this.setState({
                    showNotification: false,
                    // assignmentDetail: {},
                  });
                }}
                notifications={assignmentNotifications}
                className="tk-notification-detail__drawer"
              />
            </div>
          )}

          {showDetail && (
            <AssignmentDetail
              isVisible={showDetail}
              onClose={() => {
                this.setState({
                  showDetail: false,
                  assignmentDetail: {},
                });
                if (assignmentDetail.NumberOfUnreadNotifications > 0) {
                  const { UnreadNotifications } = assignmentDetail;
                  const notificationIdentifier =
                    UnreadNotifications[0].NotificationIdentifier;
                  onSetNotificationReadStatus(notificationIdentifier, true);
                }
              }}
              onClickMoreNotification={this.handlerNotification}
              assignment={assignmentDetail}
              from={PAGE_TYPES.DASHBOARD}
              status={status.getByStatusString(assignmentDetail.OrderStatus)}
              handleGetAttachment={this.props.onGetAttachment}
              handleOrderRate={onOrderRated}
              handleClickTimeReport={this.handleClickTimeReport}
              onCopyOrder={this.handleCopyOrder}
              onEditOrder={this.handleEditOrder}
              visibleNextPreviousButtons={visibleNextPreviousButtons}
              disablePreviousButton={disablePreviousButton}
              disableNextButton={disableNextButton}
              onPreviousNextClicked={val => {
                this.handleDrawerNextPrevious(val);
              }}
              onFilterDeviation={(val, callback) => {
                mapOnFilterDeviation(val, callback, false);
              }}
              setNotificationReadStatus={onSetNotificationReadStatus}
            />
          )}
          {this.state.showBookDirectModal &&
            !isEmpty(bookDirectOpeningHours) && (
              <BookNowModal
                isVisible={this.state.showBookDirectModal}
                onCloseClicked={() => {
                  this.setState({ showBookDirectModal: false });
                }}
                bookDirectOpeningHours={bookDirectOpeningHours}
              />
            )}
          <div className="tv-workassignment__list__mobile-fixed-bottom">
            <button
              onClick={() => {
                this.setState({
                  showStatusFilter: true,
                });
              }}
              className="tk-calendar__btn-filter"
            >
              {Translate({ content: 'calendar.filter' })}
            </button>
            <button
              className="tk-calendar__btn-newbook"
              onClick={() => {
                this.setState({
                  quickOrderDate: currentDate().add(1, 'day'),
                  showQuickOrder: true,
                  showAssigmentList: false,
                  showDetail: false,
                });
              }}
            >
              <i className="fa fa-plus mr-2" />{' '}
              {Translate({
                content: 'dashboard.addNew',
              })}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

const mapStateToProps = state => ({
  assignmentNotifications: state.notification.assignmenNotificationList,
  assignmentList: state.workAssignment.assignmentList,
  assignmentDetail: state.workAssignment.assignmentDetail,
  statusFilter: state.workAssignment.statusFilter,
  profile: state.user.profile,
  skills: state.skill.skills || [],
  dateRange: state.user.calendar.range,
  bookDirectOpeningHours: state.workAssignment.bookDirectOpeningHours,
});

const mapDispatchToProps = dispatch => ({
  onGetDashboardData: searchValue => {
    dispatch(getDashboardData(searchValue));
  },
  onUpdateUserCalendar: c => {
    dispatch(updateUserCalendar(c));
  },
  onGetNotifications: (orderIdentifier, successCallback) => {
    dispatch(
      getNotificationList(orderIdentifier, false, null, successCallback),
    );
  },
  onGetAttachment: attachmentIdentifier => {
    dispatch(getAttachment(attachmentIdentifier));
  },
  onSwitchToDetailOrder: assignment => {
    dispatch(onUpdateNewAssignmentsTemp(assignment));
  },
  onCheckUpdateCalendarEvent: () => {
    dispatch(checkUpdateCalendarEvent());
  },
  onOrderRated: (orderIdentifier, rate, comment) => {
    dispatch(setOrderRating(orderIdentifier, rate, comment));
  },
  onResetCalendar: () => {
    dispatch(resetCalendar());
  },
  getRateReasons: () => {
    dispatch(GetRatingReasons());
  },
  updateMyAssignmentFilter: (e, filterType) => {
    dispatch(updateMyAssignmentFilters(e, filterType));
  },
  mapOnFilterDeviation: (data, callback, setData) => {
    dispatch(getDeviations(data, callback, setData));
  },
  getAutoAssignOpeningHours: dateObj => {
    dispatch(autoAssignOpeningHours(dateObj));
  },
  onSetNotificationReadStatus: (
    notificationIdentifier,
    isRead,
    successCallback = () => {},
  ) => {
    dispatch(
      setNotificationReadStatus(
        notificationIdentifier,
        isRead,
        successCallback,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const CurrentTime = ({ className }) => {
  const timeRef = useRef();
  useEffect(() => {
    const getTime = setInterval(() => {
      timeRef.current.innerText = moment().format('HH:mm');
    }, 3600);
    return () => {
      clearInterval(getTime);
    };
  }, []);
  return (
    <>
      <div className={`${className}`}>
        <i className="fa fa-clock mr-2" />
        <span ref={timeRef}>{moment().format('HH:mm')}</span>
      </div>
    </>
  );
};
