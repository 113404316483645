import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { clone, isEqual, last } from 'lodash/core';
import {
  generateDefaultSession,
  generateUniqueId,
  isContactPersonValid,
  isIE,
} from '../../../Share/utils';
import { getXTimefromSpecificDate } from '../../../Share/utils/dateUtil';
import {
  Translate,
  TextField,
  DropdownList,
  TransvoiceDateRangePicker,
  TransvoiceTimePickerV2,
  HelpContext,
  Checkbox,
} from '../../../Share/components';
import { DEFAULT_EMPTY_NAME } from '../../../Share/constants';
import './style.scss';
import InfoModal from './InfoModal';
import { cloneDeep, lte } from 'lodash-es';

const gender = [
  // { value: "0", name: " - " },
  { value: '1', name: Translate({ content: 'general.male' }) },
  { value: '2', name: Translate({ content: 'general.female' }) },
];

const defaultFirstValue = {
  name: DEFAULT_EMPTY_NAME,
  value: undefined,
};

const propTypes = {
  onChange: PropTypes.func,
  validatorListener: PropTypes.func,
  isAllowLowerLevel: PropTypes.bool,
  isAllowOnlyLevel: PropTypes.bool,
  isSameProfile: PropTypes.bool,
  isHomeAddress: PropTypes.bool,
  isSameAbove: PropTypes.bool,
  genderRequirement: PropTypes.bool,
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  dateRange: PropTypes.shape({}),
  contactPerson: PropTypes.string,
  profile: PropTypes.shape({}),
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  ContactPersonOrdererRequiresEmail: PropTypes.bool,
  ContactPersonUponInterpretationRequiresEmail: PropTypes.bool,
  isSingle: PropTypes.bool,
  showOtherRequirementSection: PropTypes.bool,
  showFirstStep: PropTypes.bool,
  showSecondStep: PropTypes.bool,
};

const defaultProps = {
  onChange: () => {},
  validatorListener: () => {},
  isSameProfile: true,
  isAllowLowerLevel: false,
  isAllowOnlyLevel: false,
  isHomeAddress: false,
  isSameAbove: false,
  genderRequirement: false,
  contactPersons: [],
  services: [],
  dateRange: {},
  contactPerson: '',
  profile: {},
  languages: [],
  files: [],
  ContactPersonOrdererRequiresEmail: false,
  ContactPersonUponInterpretationRequiresEmail: false,
  isSingle: false,
  showOtherRequirementSection: false,
  showFirstStep: false,
  showSecondStep: true,
};

class SingleAssignmentForm extends Component {
  constructor(props) {
    super(props);

    const isTodayIsNotSelected = this.validationDatePicker();
    this.state = {
      isSameAbove: props.isSameAbove,
      homeAddress: props.isHomeAddress,
      isSameProfile: props.isSameProfile,
      allowLowerLevel: props.isAllowLowerLevel,
      allowOnlyLevel: props.isAllowOnlyLevel,
      genderRequirement: props.genderRequirement,
      showModal: false,
      isTodayIsNotSelected,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isAllowLowerLevel, isAllowOnlyLevel } = this.props;
    if (isAllowLowerLevel !== prevProps.isAllowLowerLevel) {
      this.setState({ allowLowerLevel: isAllowLowerLevel });
    }
    if (isAllowOnlyLevel !== prevProps.isAllowOnlyLevel) {
      this.setState({ allowOnlyLevel: isAllowOnlyLevel });
    }
  }

  validationDatePicker = () => {
    let isTodayIsNotSelected = true;
    const todayDate = moment(new Date()).format('DD/MM/YYYY');
    const selectedDate =
      this.props.dateRange.ranges.startDate.format('DD/MM/YYYY');
    if (selectedDate === todayDate) isTodayIsNotSelected = false;
    return isTodayIsNotSelected;
  };

  closeInfoModal = () => {
    this.setState({ isTodayIsNotSelected: true });
  };

  handleShowModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleShowAddress = () => {
    this.setState(
      prevState => ({
        isSameProfile: !prevState.isSameProfile,
      }),
      () => {
        const { profile, onChange } = this.props;
        const { isSameProfile } = this.state;
        if (isSameProfile) {
          onChange({
            isSameProfile,
            addressLine: profile.customerAddress
              ? profile.customerAddress.AddressRow1
              : '',
            city: profile.customerAddress ? profile.customerAddress.City : '',
            district: profile.customerAddress
              ? profile.customerAddress.CareOf
              : '',
            postalCode: profile.customerAddress
              ? profile.customerAddress.PostalCode.toString()
              : '',
          });
        } else onChange({ isSameProfile });
      },
    );
  };

  handleHideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleChange = event => {
    const { isBankIDRequired } = this.props;

    const {
      target: { name, value, type },
    } = event;
    if (type === 'checkbox') {
      this.props.onChange({ isBankIDRequired: !isBankIDRequired });
    } else {
      this.handleAssignmentChange(event);
      const eventHandleObj = { [name]: value };
      // when gender and competence level unselected requirment booleans reset to false
      if (name == 'genderValue' && value?.value === '') {
        eventHandleObj.genderRequirement = false;
      } else if (name == 'certificationLevels' && value?.value === '') {
        eventHandleObj.isAllowLowerLevel = false;
        eventHandleObj.isAllowOnlyLevel = false;
      }
      this.props.onChange({ ...eventHandleObj });
    }
  };

  handleSessionChange = (e, key, rowNumber) => {
    const dateRange = clone(this.props.dateRange); // use clone() to fix object reference issue
    dateRange[key] = e;
    const baseStartTime = dateRange ? dateRange.startTime : null;
    const baseEndTime = dateRange ? dateRange.endTime : null;

    if (key !== 'startTime' && key !== 'endTime') {
      const selectedDate = e.startDate.format('DD/MM/YYYY');
      const todayDate = moment(new Date()).format('DD/MM/YYYY');
      if (selectedDate === todayDate) {
        this.setState({ isTodayIsNotSelected: false });
        e.startDate.add('1', 'days');
        e.endDate.add('1', 'days');
      }
    }

    // update start/end time values of this session
    if (key === 'startTime') {
      const { startTime } = clone(dateRange); // use clone() to fix object reference issue
      const { endTime } = dateRange;

      dateRange.endTime = getXTimefromSpecificDate(
        startTime,
        dateRange.durationDifference,
        'minutes',
      );
    } else if (key === 'endTime') {
      const { endTime } = clone(dateRange); // use clone() to fix object reference issue
      const { startTime } = dateRange;

      const durationDiffInMinutes = moment
        .duration(endTime - startTime)
        .asMinutes();
      dateRange.durationDifference = durationDiffInMinutes;
    }

    const isChangeSerialDuration = true;
    this.props.onChange({ dateRange, isChangeSerialDuration });
  };

  handleSessionDateChange = (e, i) => {
    const { dateRange } = this.props;
    const { key } = dateRange.ranges;
    if (key && e[key]) {
      const { startDate, endDate } = e[key];
      if (startDate && endDate) {
        this.handleSessionChange(
          {
            startDate: moment(startDate),
            endDate: moment(endDate),
            key,
          },
          'ranges',
          i,
        );
      }
    }
  };

  handleSameAbove = () => {
    const { contactPerson } = this.props;
    if (contactPerson !== '') {
      const isSameAbove = !this.state.isSameAbove;
      this.setState({ ...this.state, isSameAbove });
      this.props.onChange({ isSameAbove });
      const event = {
        target: {
          value: contactPerson,
        },
      };
      this.changeContactOrderValue(event);
    }
  };

  handleCheckBoxChange = (e, key) => {
    if (key === 'allowLowerLevel') {
      const isAllowLowerLevel = !this.state.isAllowLowerLevel;
      this.setState({ ...this.state, isAllowLowerLevel });
      this.props.onChange({ isAllowLowerLevel });
    }
    if (key === 'homeAddress') {
      const isHomeAddress = !this.state.isHomeAddress;
      this.setState({ ...this.state, isHomeAddress });
      this.props.onChange({ isHomeAddress });
    }
    if (key === 'genderRequirement') {
      const genderRequirement = !this.state.genderRequirement;
      this.setState({ ...this.state, genderRequirement });
      this.props.onChange({ genderRequirement });
    }
    if (key === 'doRequireSameResourceForAllWorkassignments') {
      if (e.target.checked && this.props.dateRange) {
        this.props.onChange({
          OrderGroupRequestData: {
            Description: '',
            DoRequireSameResourceForAllWorkAssignments: true,
          },
        });
      } else {
        this.props.onChange({
          OrderGroupRequestData: {
            Description: '',
            DoRequireSameResourceForAllWorkAssignments: false,
          },
        });
      }
    }
  };

  handleFileDrop = file => {
    if (file) {
      const { files } = this.props;
      files.push(file);
      this.props.onChange({ file });
    }
  };

  handleFileRemove = i => {
    if (i > -1) {
      const files = clone(this.props.files);
      files.splice(i, 1);
      this.props.onChange({ files });
    }
  };

  handleAssignmentChange = e => {
    const {
      target: { value },
    } = e;
    const { services } = this.props;
    const selectedService = services.filter(
      item => item.ServiceIdentifier === value.value,
    );
    if (selectedService.length > 0) {
      const availableCompetences = selectedService[0].AvailableCompetences.map(
        (item, i) => ({
          name: item.CompetenceLevelName,
          value: item.CompetenceLevelIdentifier,
        }),
      );
      this.setState({ ...this.state, availableCompetences });
    }
  };

  changeContactValue = event => {
    const { contactPersons } = this.props;
    const { isSameAbove } = this.state;
    const selectedContactPerson = event.target.value;

    const contactPersonData = {
      contactPerson: selectedContactPerson ? selectedContactPerson.value : '',
      contactNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberMobile
        : '',
      contactDirectNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberDirect
        : '',
      contactEmail: selectedContactPerson ? selectedContactPerson.Email : '',
    };

    this.props.onChange(contactPersonData);
    if (isSameAbove && event.target.value !== 'none') {
      const e = {
        target: {
          value: selectedContactPerson.ContactPersonIdentifier,
        },
      };
      this.changeContactOrderValue(e);
    }

    if (isSameAbove && event.target.value === 'none') {
      this.setState({
        ...this.state,
        isSameAbove: !isSameAbove,
      });
    }
  };

  changeContactOrderValue = event => {
    const { contactPersons } = this.props;
    const selectedContactPerson = event.target.value;

    const contactPersonData = {
      orderContactPerson: selectedContactPerson
        ? selectedContactPerson.value
        : '',
      orderContactNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberMobile
        : '',
      orderContactDirectNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberDirect
        : '',
      orderEmail: selectedContactPerson ? selectedContactPerson.Email : '',
    };
    this.props.onChange(contactPersonData);
  };

  getCertificationLevels = () => {
    const { services, typeOfAssignment } = this.props;

    let result = [];
    services.forEach(service => {
      if (
        service.ServiceIdentifier === typeOfAssignment.value &&
        service.AvailableCompetences
      ) {
        result = service.AvailableCompetences.map(availableItem => ({
          name: availableItem.CompetenceLevelName,
          value: availableItem.CompetenceLevelIdentifier,
        }));
      }
    });

    return result;
  };

  generateMediaType = () => {
    const { services } = this.props;
    const options = [];
    if (services) {
      services.map(service => {
        if (service.DigitalMeetingPlatforms) {
          service.DigitalMeetingPlatforms.map(item => {
            options.push({
              name: item.DigitalMeetingPlatformName,
              value: item.DigitalMeetingPlatformIdentifier,
            });

            return null;
          });
        }
        return null;
      });
    }

    return options;
  };

  getValueFromList = (filterText, list) => {
    if (filterText === null) return null;
    let filteredItems;
    if (list) {
      list.map(item => {
        if (item.DigitalMeetingPlatforms) {
          filteredItems = item.DigitalMeetingPlatforms.filter(
            x => x.DigitalMeetingPlatformIdentifier === filterText,
          );
        }
        return null;
      });
    }

    return filteredItems ? filteredItems[0] : null;
  };

  generateFieldsOnAssignmentTypeProps = () => {
    const {
      selectedAssignmentTypeData,
      meetingLink,
      phoneNumber,
      meetingPassCode,
      isBankIDRequired,
      meetingPlatform,
      services,
    } = this.props;
    const renderedFields = [];

    if (selectedAssignmentTypeData) {
      selectedAssignmentTypeData.map(item => {
        if (item.DoRequireMeetingUrl) {
          const meetingField = (
            <div className="form-group col-12 mb-2">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.videoLink" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingLink"
                maxLength={500}
                value={meetingLink}
                placeholder={Translate({
                  content: 'singleAssignment.videoLinkPlaceHolder',
                })}
                validators={['required', 'urlformat']}
                errorMessages={[
                  Translate({ content: 'singleAssignment.videoLinkRequired' }),
                  'Videolänk måste börja med "http://" eller "https://"',
                ]}
              />
            </div>
          );
          const meetingPassCodeFiled = (
            <div className="form-group col-12 mb-2">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.passCodeHeader" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingPassCode"
                style={{ height: '40px' }}
                maxLength={500}
                value={meetingPassCode}
                placeholder={Translate({
                  content: 'singleAssignment.passCodePlaceHolder',
                })}
                errorMessages={[
                  Translate({ content: 'singleAssignment.passCodeRequired' }),
                ]}
              />
            </div>
          );
          const meetingMedia = (
            <div className="form-group col-6 mb-2">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.platform" />
              </label>
              <DropdownList
                onChange={this.handleChange}
                name="meetingPlatform"
                value={meetingPlatform.value === null ? null : meetingPlatform}
                className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                options={this.generateMediaType()}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.platform',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );
          const bankIDRequired = (
            <div className="form-group col-6 mb-2">
              <div className="bank-id" />
              <Checkbox
                label={Translate({
                  content: 'singleAssignment.bankIdRequired',
                })}
                handleChange={this.handleChange}
                defaultChecked={isBankIDRequired}
              />
            </div>
          );

          renderedFields.push(
            meetingField,
            meetingPassCodeFiled,
            meetingMedia,
            bankIDRequired,
          );
        }
        if (item.DoRequireMeetingPhoneNumber) {
          const phoneNumberField = (
            <div className="form-group col-12 mb-2">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.telephoneNumber" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="phoneNumber"
                maxLength={50}
                value={phoneNumber}
                placeholder={Translate({
                  content: 'singleAssignment.telephonePlaceHolder',
                })}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.telephoneNumber',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );
          const meetingPassCodeFiled = (
            <div className="form-group col-12 mb-2">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.passCodeHeader" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingPassCode"
                style={{ height: '40px' }}
                maxLength={300}
                value={meetingPassCode}
                placeholder={Translate({
                  content: 'singleAssignment.passCodePlaceHolder',
                })}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.passCodeHeader',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );
          renderedFields.push(phoneNumberField, meetingPassCodeFiled);
        }
      });
      return renderedFields;
    }
  };

  handleRadioButtonChange = (e, controlName) => {
    const contollerValue = e.target.value;
    switch (controlName) {
      case 'genderRequirement':
        {
          let genderRequirement = false;
          if (contollerValue == 2) {
            genderRequirement = true;
          }
          this.setState({ ...this.state, genderRequirement });
          this.props.onChange({ genderRequirement });
        }
        break;
      case 'allowLowerLevel':
        {
          let isAllowLowerLevel = false;
          let isAllowOnlyLevel = false;
          if (contollerValue == 2) {
            isAllowLowerLevel = true;
            isAllowOnlyLevel = false;
          }
          this.setState({
            ...this.state,
            allowLowerLevel: isAllowLowerLevel,
            allowOnlyLevel: isAllowOnlyLevel,
          });
          this.props.onChange({ isAllowLowerLevel, isAllowOnlyLevel });
        }
        break;
      case 'allowOnlyLevel':
        if (contollerValue == 3) {
          const isAllowLowerLevel = false;
          const isAllowOnlyLevel = true;
          this.setState({
            ...this.state,
            allowLowerLevel: isAllowLowerLevel,
            allowOnlyLevel: isAllowOnlyLevel,
          });
          this.props.onChange({ isAllowLowerLevel, isAllowOnlyLevel });
        }
        break;
      case 'requestedInterpreterPreferedLevel':
        {
          const requestedInterpreterPreferedLevel = contollerValue;

          this.setState({ ...this.state, requestedInterpreterPreferedLevel });
          this.props.onChange({
            requestedInterpreterPreferedLevel,
          });
        }
        break;
      default:
        break;
    }
  };

  isSameDayTime = sess => {
    let returnValue = true;
    let session = cloneDeep(sess);

    const beginningTime = moment({
      h: session.startTime.hours(),
      m: session.startTime.minutes(),
    });
    const endTime = moment({
      h: session.endTime.hours(),
      m: session.endTime.minutes(),
    });
    session.ranges.startDate = session.ranges.startDate.clone();
    session.ranges.endDate = session.ranges.endDate.clone();
    if (
      (beginningTime.isAfter(endTime) || endTime.isSame(beginningTime)) &&
      session.ranges.endDate.isSame(session.ranges.startDate, 'day')
    ) {
      returnValue = false;
    }
    return returnValue;
  };

  render() {
    const {
      validatorListener,
      dateRange,
      certificationLevels,
      yourReferenceNumber,
      addressLine,
      district,
      city,
      postalCode,
      language,
      assignmentDescription,
      additionalRequirements,
      assignmentSubskill,
      requestedInterpreter,
      requestedInterpreterPreferedLevel,
      contactPerson,
      contactNumber,
      contactEmail,
      contactDirectNumber,
      orderContactPerson,
      orderContactNumber,
      orderContactDirectNumber,
      orderEmail,
      genderRequirement,
      languages,
      contactPersons,
      services,
      genderValue,
      typeOfAssignment,
      onHandleModal,
      createdFrom,
      ContactPersonOrdererRequiresEmail,
      ContactPersonUponInterpretationRequiresEmail,
      isSingle,
      isEdit,
      showFirstStep,
      showSecondStep,
    } = this.props;
    const {
      isSameAbove,
      homeAddress,
      allowLowerLevel,
      allowOnlyLevel,
      isSameProfile,
      isTodayIsNotSelected,
    } = this.state;

    const assignmentTypes = services
      ? services.map(item => ({
          name: item.ServiceName,
          value: item.ServiceIdentifier,
        }))
      : [];

    const cerLevels = this.getCertificationLevels();

    const getValuefromList = (filterText, list) => {
      if (filterText === null || filterText === '') return null;
      const filteredItems = list.filter(x => x.value === filterText);
      return filteredItems ? filteredItems[0] : null;
    };

    const eventValuetransformForDropDown = (e, controllerName) => {
      this.handleChange(e);
    };

    const isContactPersonUponInterpretationValid =
      !contactPerson ||
      contactPerson == '' ||
      isContactPersonValid(
        ContactPersonUponInterpretationRequiresEmail,
        contactPerson,
        contactPersons,
      );

    const isContactPersonOrdererValid =
      !orderContactPerson ||
      orderContactPerson == '' ||
      isContactPersonValid(
        ContactPersonOrdererRequiresEmail,
        orderContactPerson,
        contactPersons,
      );

    return (
      <React.Fragment>
        {showFirstStep && (
          <div className="quick-order-cont text-left pl-2 pr-3 row  align-content-around">
            <div className="form-group col-12 col-lg-4">
              <label>
                {' '}
                <Translate content="singleAssignment.selectDatePeriod" />
              </label>

              <div className=" tv-single-assign-form__input">
                <TransvoiceDateRangePicker
                  ranges={dateRange.ranges}
                  displayFormat="YYYY-MM-DD"
                  onChange={e => this.handleSessionDateChange(e)}
                />
              </div>
            </div>
            <div className="form-row col-12 col-lg-8 justify-content-between mx-0 w-100">
              <div className="form-group col-12 col-md-5  px-0">
                <label>
                  <Translate content="singleAssignment.startTime" />
                </label>
                <TransvoiceTimePickerV2
                  key={`${generateUniqueId()}-TransvoiceTimePickerV2-start`}
                  defaultValue={dateRange.startTime}
                  startFromDayBegin
                  handleValueChange={e =>
                    this.handleSessionChange(e, 'startTime')
                  }
                />
              </div>
              <div className="form-group col-12 col-md-5  px-0">
                <label>
                  <Translate content="singleAssignment.endTime" />
                </label>
                <TransvoiceTimePickerV2
                  key={`${generateUniqueId()}-TransvoiceTimePickerV2-end`}
                  defaultValue={dateRange.endTime}
                  durationCalculate={dateRange.startTime}
                  allowNextDay
                  isNotRanged={false}
                  handleValueChange={e =>
                    this.handleSessionChange(e, 'endTime')
                  }
                />
                {!this.isSameDayTime(dateRange) && (
                  <span className="tv-form__warning">
                    <i className="fa fa-exclamation-circle mr-1"></i>Nästa dag
                  </span>
                )}
                {dateRange.ranges.startDate.format('DD/MM/YYYY') ===
                  moment(new Date()).format('DD/MM/YYYY') &&
                  !isEdit && (
                    <div className="tv-col-12">
                      <span className="tv-form__error">
                        <Translate content="singleAssignment.dateRangePickerTodayWarning" />
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="typeOfAssignment">
                <Translate content="singleAssignment.typeOfAssignment" />
              </label>
              <DropdownList
                className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="typeOfAssignment"
                options={assignmentTypes}
                value={getValuefromList(
                  typeOfAssignment.value,
                  assignmentTypes,
                )}
                onChange={this.handleChange}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.typeOfAssignment',
                      }),
                    },
                  }),
                ]}
              />
            </div>
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="language">
                <Translate content="singleAssignment.language" />
              </label>

              <DropdownList
                onChange={eventValuetransformForDropDown}
                name="language"
                value={getValuefromList(language.value, languages)}
                className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                options={languages}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.language',
                      }),
                    },
                  }),
                ]}
              />
            </div>
            {this.generateFieldsOnAssignmentTypeProps()}
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="contactPerson">
                <Translate content="singleAssignment.contactPerson" />
              </label>
              <DropdownList
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isContactPersonUponInterpretationValid
                    ? ''
                    : 'tv-form__control--error'
                }`}
                name="contactPerson"
                options={contactPersons}
                value={getValuefromList(contactPerson, contactPersons)}
                onChange={(e, v) => {
                  this.changeContactValue(e);
                }}
                footer={
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonUponInterpretationRequiresEmail,
                      );
                    }}
                    className="tv-dropdown__footer-conactperson-add"
                  >
                    + lägg till tolkanvändare
                  </span>
                }
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.contactPerson',
                      }),
                    },
                  }),
                ]}
              />
              {!isContactPersonUponInterpretationValid && (
                <span class="tv-form__error text-justify">
                  E-post krävs.
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonUponInterpretationRequiresEmail,
                        'ContactPersonUponInterpretation',
                        contactPerson,
                      );
                    }}
                    className="contact-person-email-edit"
                  >
                    + Lägg till här
                  </span>
                </span>
              )}
            </div>
            <div className="form-group col-12 col-lg-6 mb-5 mb-lg-5">
              <label>
                <Translate content="singleAssignment.contactOrderPerson" />
              </label>

              <DropdownList
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isContactPersonOrdererValid ? '' : 'tv-form__control--error'
                }`}
                name="orderContactPerson"
                options={contactPersons}
                footer={
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(ContactPersonOrdererRequiresEmail);
                    }}
                    className="tv-dropdown__footer-conactperson-add"
                  >
                    + lägg till tolkanvändare
                  </span>
                }
                value={getValuefromList(orderContactPerson, contactPersons)}
                onChange={(e, v) => {
                  this.changeContactOrderValue(e);
                }}
                disabled={isSameAbove ? 'disabled' : ''}
                validators={isSameAbove ? [] : ['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.orderContactPerson',
                      }),
                    },
                  }),
                ]}
              />
              {!isContactPersonOrdererValid && (
                <span className="tv-form__error text-justify">
                  E-post krävs.
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonOrdererRequiresEmail,
                        'ContactPersonOrderer',
                        orderContactPerson,
                      );
                    }}
                    className="contact-person-email-edit"
                  >
                    + Lägg till här
                  </span>
                </span>
              )}
            </div>
          </div>
        )}
        {showSecondStep && (
          <div className="quick-order-cont text-left pl-2 pr-3 mh-100 row mb-5 mb-md-0">
            <div
              className={`form-group col-12 col-md-6 col-lg-6 ${
                genderValue &&
                genderValue?.value != '' &&
                genderValue?.value != '0'
                  ? 'mb-4'
                  : ''
              }`}
            >
              <label>
                <Translate content="singleAssignment.genderRequirement" />
              </label>

              <DropdownList
                className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="genderValue"
                options={gender}
                value={getValuefromList(
                  genderValue ? genderValue.value : null,
                  gender,
                )}
                onChange={e => {
                  eventValuetransformForDropDown(e, 'genderValue');
                }}
              />
              {genderValue &&
                genderValue?.value != '' &&
                genderValue?.value != '0' && (
                  <div className="d-flex ">
                    <TransvoiceRadioButton
                      text={Translate({
                        content: 'singleAssignment.preferChoice',
                      })}
                      name="genderRequirement"
                      checked={!genderRequirement}
                      value={1}
                      onChange={e =>
                        this.handleRadioButtonChange(e, 'genderRequirement')
                      }
                      className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                    />
                    <TransvoiceRadioButton
                      text={Translate({
                        content: 'singleAssignment.choiceRequired',
                      })}
                      onChange={e =>
                        this.handleRadioButtonChange(e, 'genderRequirement')
                      }
                      name="genderRequirement"
                      value={2}
                      checked={genderRequirement}
                      className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                    />
                  </div>
                )}
            </div>
            <div
              className={`form-group col-12 col-md-6 col-lg-6 ${
                certificationLevels && certificationLevels?.value != ''
                  ? 'mb-4'
                  : ''
              }`}
            >
              <label>
                <Translate content="singleAssignment.certificationLevel" />
              </label>

              <DropdownList
                onChange={e =>
                  eventValuetransformForDropDown(e, 'certificationLevels')
                }
                name="certificationLevels"
                value={getValuefromList(
                  certificationLevels ? certificationLevels.value : null,
                  cerLevels,
                )}
                className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                options={cerLevels}
              />
              {certificationLevels && certificationLevels?.value != '' && (
                <div className="d-flex flex-wrap">
                  <TransvoiceRadioButton
                    text={Translate({
                      content: 'singleAssignment.preferChoice',
                    })}
                    name="allowLowerLevel"
                    checked={!allowLowerLevel && !allowOnlyLevel}
                    value={1}
                    onChange={e =>
                      this.handleRadioButtonChange(e, 'allowLowerLevel')
                    }
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                  />
                  <TransvoiceRadioButton
                    text={Translate({
                      content: 'singleAssignment.allowLowerLevel',
                    })}
                    name="allowLowerLevel"
                    checked={allowLowerLevel && !allowOnlyLevel}
                    onChange={e =>
                      this.handleRadioButtonChange(e, 'allowLowerLevel')
                    }
                    value={2}
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container text-nowrap"
                  />
                  <TransvoiceRadioButton
                    text={Translate({
                      content: 'singleAssignment.allowOnlyLevel',
                    })}
                    name="allowLowerLevel"
                    checked={allowOnlyLevel && !allowLowerLevel}
                    onChange={e =>
                      this.handleRadioButtonChange(e, 'allowOnlyLevel')
                    }
                    value={3}
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container text-nowrap"
                  />
                </div>
              )}
            </div>
            <div
              className={`form-group col-12 col-lg-6 ${
                requestedInterpreter && requestedInterpreter != ''
                  ? 'mb-4'
                  : 'mb-1'
              }`}
            >
              <label>
                <Translate content="singleAssignment.requestedInterpreterLabel" />
              </label>

              <TextField
                validatorListener={validatorListener}
                placeholder={Translate({
                  content: 'singleAssignment.requestedInterpreterPlaceholder',
                })}
                onChange={this.handleChange}
                className=" tv-single-assign-form__control"
                name="requestedInterpreter"
                value={requestedInterpreter}
              />
              {requestedInterpreter && requestedInterpreter != '' && (
                <div className="d-flex ">
                  <TransvoiceRadioButton
                    text={Translate({
                      content: 'singleAssignment.preferChoice',
                    })}
                    name="requestedInterpreterPreferedLevel"
                    value={2}
                    onChange={e =>
                      this.handleRadioButtonChange(
                        e,
                        'requestedInterpreterPreferedLevel',
                      )
                    }
                    checked={
                      requestedInterpreterPreferedLevel == 2 ||
                      !requestedInterpreterPreferedLevel
                    }
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                  />
                  <TransvoiceRadioButton
                    text={Translate({
                      content: 'singleAssignment.choiceRequired',
                    })}
                    value={1}
                    onChange={e =>
                      this.handleRadioButtonChange(
                        e,
                        'requestedInterpreterPreferedLevel',
                      )
                    }
                    checked={requestedInterpreterPreferedLevel == 1}
                    name="requestedInterpreterPreferedLevel"
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container text-nowrap"
                  />
                  <TransvoiceRadioButton
                    text="Vidtalad"
                    value={3}
                    onChange={e =>
                      this.handleRadioButtonChange(
                        e,
                        'requestedInterpreterPreferedLevel',
                      )
                    }
                    checked={requestedInterpreterPreferedLevel == 3}
                    name="requestedInterpreterPreferedLevel"
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container text-nowrap"
                  />
                </div>
              )}
            </div>
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="additionalRequirements">
                <Translate content="singleAssignment.additionalRequestedRequirment" />
              </label>
              <TextField
                placeholder={Translate({
                  content:
                    'singleAssignment.additionalRequestedRequirmentPlaceholder',
                })}
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="additionalRequirements"
                maxLength={500}
                value={additionalRequirements}
              />
            </div>

            <div className="col-12 form-group ">
              <label>
                <Translate content="singleAssignment.yourReferenceNumber" />
                <HelpContext
                  theme="light"
                  style={{}}
                  content={Translate({
                    content: 'helpContent.infoForTransvoiceHelp',
                  })}
                />
              </label>

              <TextField
                validatorListener={validatorListener}
                placeholder={Translate({
                  content: 'singleAssignment.yourReferenceNumberPlaceHolders',
                })}
                maxLength={100}
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="yourReferenceNumber"
                value={yourReferenceNumber}
              />
            </div>
            <div className="col-12 form-group mb-4">
              <label>
                <Translate content="taskDetail.infoForInterpreter" />
                <HelpContext
                  theme="light"
                  style={{}}
                  content={Translate({
                    content: 'helpContent.infoForInterpreterHelp',
                  })}
                />
              </label>

              <TextField
                validatorListener={validatorListener}
                placeholder={Translate({
                  content: 'singleAssignment.typeNotesForResource',
                })}
                onChange={this.handleChange}
                multiline
                className="tv-single-assign-form__textfield tv-single-assign-form__textarea tv-single-assign-form__control"
                name="assignmentDescription"
                value={assignmentDescription}
              />
            </div>

            <div className="form-group col-12  mb-3 ">
              <label>
                <Translate content="singleAssignment.enterADifferentAddress" />
                <HelpContext
                  theme="light"
                  style={{}}
                  content={Translate({
                    content: 'helpContent.enterADifferentAddressHelp',
                  })}
                />
              </label>

              <TextField
                validatorListener={validatorListener}
                onChange={this.handleChange}
                placeholder={Translate({
                  content: 'singleAssignment.addressPlaceHolder',
                })}
                className=" tv-single-assign-form__control"
                name="addressLine"
                value={addressLine}
                validators={isSameProfile ? [] : ['required']}
                maxLength={300}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.addressLine',
                      }),
                    },
                  }),
                ]}
              />
            </div>

            <div className="col-6 col-6 col-md-6 form-group mb-1 mb-md-3">
              <label>
                <Translate content="singleAssignment.postalNumber" />
              </label>
              <TextField
                validatorListener={validatorListener}
                onChange={this.handleChange}
                type="number"
                placeholder={Translate({
                  content: 'singleAssignment.postalCodePlaceHolder',
                })}
                className=" tv-single-assign-form__control"
                name="postalCode"
                maxLength={300}
                value={postalCode.toString()}
                validators={
                  isSameProfile
                    ? []
                    : ['required', 'isNumber', 'checkNumLength: 5']
                }
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.postalCode',
                      }),
                    },
                  }),
                  Translate({
                    content: 'validator.isNotValid',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.postalCode',
                      }),
                    },
                  }),
                  Translate({
                    content: 'validator.requireLength',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.postalCode',
                      }),
                      number: 5,
                    },
                  }),
                ]}
              />
            </div>
            <div className="col-6 col-6 col-md-6 form-group mb-1 mb-md-3">
              <label>
                <Translate content="singleAssignment.cityNew" />
              </label>
              <TextField
                validatorListener={validatorListener}
                onChange={this.handleChange}
                placeholder={Translate({
                  content: 'singleAssignment.cityPlaceHolder',
                })}
                className=" tv-single-assign-form__control"
                name="city"
                value={city}
                validators={isSameProfile ? [] : ['required']}
                maxLength={300}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.cityNew',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

SingleAssignmentForm.propTypes = propTypes;
SingleAssignmentForm.defaultProps = defaultProps;

export default SingleAssignmentForm;

const TransvoiceRadioButton = ({
  className,
  name,
  onChange,
  checked,
  value,
  text = '',
  disabled = false,
}) => (
  <label className={`tv-form__checkbox-container ${className}`}>
    <input
      disabled={disabled}
      type="radio"
      name={name}
      onChange={onChange}
      defaultChecked={checked}
      value={value}
    />
    <span className="tv-form__checkbox-checkmark" />
    {text}
  </label>
);
